import { ContinueButton } from "components/ui/ContinueButton";
import { registerDecision } from "GameSlice";
import { RepeatButton } from "components/ui/RepeatButton";
import { sceneBackgroundMusicEnabled } from "SoundSlice";
import { useAppDispatch } from "hooks";
import { useCallback, useContext, useEffect, useState } from "react";
import DecisionSlider from "components/ui/DecisionSlider";
import StateMachineContext from "lib/StateMachineContext";
import UserInputScene from "components/ui/layout/UserInputLayout";
import { useTranslation } from "react-i18next";

export interface InputProps {
  setShouldResetCount: (val: boolean) => void;
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const { setShouldResetCount } = props;

  const { send } = useContext(StateMachineContext);
  const dispatch = useAppDispatch();

  const [temporaryDecision, setTemporaryDecision] = useState(0);

  useEffect(() => {
    dispatch(sceneBackgroundMusicEnabled(false));
  }, [dispatch]);

  const setDecision = () => {
    if (!send) return;
    dispatch(
      registerDecision({
        scene: "SceneA",
        decision: [{ sliderName: "fishing", decision: temporaryDecision }],
      })
    );
    send("FISH", { decision: temporaryDecision });
  };

  const onClickRepeat = useCallback(() => {
    dispatch(sceneBackgroundMusicEnabled(true));
    setShouldResetCount(true);
  }, [dispatch, setShouldResetCount]);

  const { t } = useTranslation();

  return (
    <UserInputScene
      subline={t("sceneA.step5.input")}
      content={
        <DecisionSlider
          textLess={t("general.slider.lessFishing")}
          textMore={t("general.slider.moreFishing")}
          onSetValue={setTemporaryDecision}
        />
      }
      buttons={
        temporaryDecision !== 0 ? (
          <ContinueButton onClick={setDecision} />
        ) : (
          <>
            <RepeatButton onClick={onClickRepeat}></RepeatButton>
          </>
        )
      }
    ></UserInputScene>
  );
};

export default Input;
