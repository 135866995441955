import ExplanationLayout from "components/prefabs/ExplanationLayout";
import { useTranslation } from "react-i18next";
// Assets
import explanationImage from "assets/scenes/D_01/D_05_06_grafik.png";
import video from "assets/scenes/D_01/D_05_04_bg.mp4";

import styles from "../../../../prefabs/Explanation.module.scss";

const Explanation = () => {
  const { t } = useTranslation();

  const ExplanationAdditionalInformation = (
    <div className={styles.explanationTextD}>
      <p>{t("sceneD.step5.explanation.text")}</p>
    </div>
  );

  return (
    <ExplanationLayout
      headline={t("sceneD.step5.explanation.headline")}
      video={video}
      contentImage={explanationImage}
      sideContent={ExplanationAdditionalInformation}
    ></ExplanationLayout>
  );
};

export default Explanation;
