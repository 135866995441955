import { StepComponent } from "types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import GameManager from "GameManager";
import SimpleVideoStep from "components/prefabs/SimpleVideoStep";
import TextTypewriter from "components/ui/TextTypewriter";
// Assets
import mapImage from "assets/scenes/D_01/D_01_01_map.jpg";
import video from "assets/scenes/E_01/E_05_01_bg.mp4";

export const mapBackground: string = mapImage;

const Step5: StepComponent = () => {
  const [resetTypeWriter, setResetTypeWriter] = useState(false);
  const [typewriterActive, setTypewriterActive] = useState(true);

  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      active={typewriterActive}
      resetTypeWriter={resetTypeWriter}
      setResetTypeWriter={setResetTypeWriter}
      timesheet={[
        {
          offset: 0.5,
          text: t("sceneE.step5.first"),
          waitAfter: 4.5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step5.second"),
          waitAfter: 5.5,
          clear: true,
        },
      ]}
    />
  );

  return (
    <SimpleVideoStep
      setResetTextTypeWriter={setResetTypeWriter}
      video={video}
      onClickContinue={() => GameManager.next()}
      content={{ position: "bottom", element: typewriter }}
      setTypewriterActive={setTypewriterActive}
    />
  );
};

const exports = { component: Step5, marker: [], mapBackground };
export default exports;
