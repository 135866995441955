import SceneB from "./Scene";
import steps from "./steps";

const IDENTIFIER = "SCENEB";

const component = SceneB;

const Scene = {
  IDENTIFIER,
  steps,
  component,
};

export default Scene;
