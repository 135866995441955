import { StepComponent } from "types";
import FullscreenVideo from "components/video/FullscreenVideo";
import TypewriterAndButtonsOverlay from "components/ui/layout/TypewriterAndButtonsOverlay";
import { useCallback, useEffect, useState } from "react";
import { PlayOrPauseButton } from "components/ui/PlayOrPauseButton";

type SimpleVideoStepProps = {
  video: string;
  content: {
    position: "top" | "bottom";
    element: JSX.Element;
  };
  setInputVisible: (shouldBeVisible: boolean) => void;
  secondsForInput: number;
  shouldResetCount: boolean;
  setShouldResetCount: (val: boolean) => void;
  setTypewriterActive?: (val: boolean) => void;
};

const SimpleIntroStep: StepComponent<SimpleVideoStepProps> = ({
  video,
  content,
  setInputVisible,
  secondsForInput,
  setShouldResetCount,
  shouldResetCount,
  setTypewriterActive,
}) => {
  const [resetCount, setResetCount] = useState(0);
  const [videoIsPaused, setVideoIsPaused] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [videoIsReady, setVideoIsReady] = useState(false);

  const topContent = content.position === "top" ? content.element : undefined;
  const bottomContent =
    content.position === "bottom" ? content.element : undefined;

  useEffect(() => {
    if (shouldResetCount) {
      setResetCount(resetCount + 1);
      setShouldResetCount(false);
      setButtonsVisible(false);
    }
  }, [resetCount, shouldResetCount, setShouldResetCount]);

  const onClickPause = useCallback(() => {
    setVideoIsPaused(!videoIsPaused);
    if (setTypewriterActive) {
      setTypewriterActive(videoIsPaused);
    }
  }, [videoIsPaused, setTypewriterActive]);

  const onVideoEnd = () => {
    setButtonsVisible(true);
  };

  const ButtonBar = buttonsVisible ? (
    <></>
  ) : (
    <PlayOrPauseButton onClick={onClickPause} isVideoPaused={videoIsPaused} />
  );

  useEffect(() => {
    if (videoIsReady) {
      if (setTypewriterActive) {
        setTypewriterActive(true);
      }
    } else {
      if (setTypewriterActive) {
        setTypewriterActive(false);
      }
    }
  }, [setTypewriterActive, videoIsReady]);

  return (
    <>
      <FullscreenVideo
        onProgress={{ seconds: secondsForInput, callback: setInputVisible }}
        video={video}
        resetCount={resetCount}
        videoIsPaused={videoIsPaused}
        onVideoEnded={onVideoEnd}
        setVideoIsReady={setVideoIsReady}
        videoIsReady={videoIsReady}
      />
      <TypewriterAndButtonsOverlay
        buttonBarContent={ButtonBar}
        topContent={topContent}
        bottomContent={bottomContent}
      />
    </>
  );
};

export default SimpleIntroStep;
