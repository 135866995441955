import ExplanationLayout from "components/prefabs/ExplanationLayout";
import styles from "../../../../prefabs/Explanation.module.scss";
import { useTranslation } from "react-i18next";
// Assets
import explanationImage from "assets/scenes/B_01/B_05_06_grafik.png";
import video from "assets/scenes/B_01/B_05_04_bg.mp4";

const Explanation = () => {
  const { t } = useTranslation();

  const ExplanationAdditionalInformation = (
    <div className={styles.explanationText}>
      <div className={styles.explanationTextSingle}>
        <h3 className={styles.explanationTextHeadline}>
          {t("sceneB.step5.explanation.headline1")}
        </h3>
        <div className={styles.explanationTextEntries}>
          <span>{t("sceneB.step5.explanation.list1")}</span>
        </div>
      </div>
      <div className={styles.explanationTextTopContainer}>
        <div className={styles.explanationTextTop}>
          <h3 className={styles.explanationTextHeadline}>
            {t("sceneB.step5.explanation.headline2")}
          </h3>
          <div className={styles.explanationTextEntries}>
            <span>{t("sceneB.step5.explanation.list2")}</span>
          </div>
        </div>
        <div className={styles.explanationTextTop}>
          <h3 className={styles.explanationTextHeadline}>
            {t("sceneB.step5.explanation.headline3")}
          </h3>
          <div className={styles.explanationTextEntries}>
            <span>{t("sceneB.step5.explanation.list3")}</span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <ExplanationLayout
      headline={t("sceneB.step5.explanation.headline")}
      video={video}
      contentImage={explanationImage}
      sideContent={ExplanationAdditionalInformation}
    ></ExplanationLayout>
  );
};

export default Explanation;
