import { isMobile } from "react-device-detect";
import { MouseEvent, useCallback } from "react";
import { Position } from "types";
import { useClick } from "components/ClickProvider";
import Sound from "react-sound";

import styles from "./Marker.module.scss";

import PingSound from "assets/sounds/A_01_01_ping_v01.mp3";

type MarkerProps = {
  position: Position;
  onClick: (event: MouseEvent) => void;
  padding?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
};

const Marker = ({ position, onClick, padding }: MarkerProps) => {
  const click = useClick();

  const onClickMarker = useCallback(
    (event: MouseEvent) => {
      click();
      onClick(event);
    },
    [click, onClick]
  );

  return (
    <div
      onClick={onClickMarker}
      className={styles.marker}
      style={{
        top: `${position.y}px`,
        left: `${position.x}px`,
        paddingTop: padding && padding.top ? `${padding.top}px` : 0,
        paddingBottom: padding && padding.bottom ? `${padding.bottom}px` : 0,
        paddingRight: padding && padding.right ? `${padding.right}px` : 0,
        paddingLeft: padding && padding.left ? `${padding.left}px` : 0,
      }}
    >
      {!isMobile && (
        <Sound url={PingSound} playStatus={"PLAYING"} loop={true} />
      )}
    </div>
  );
};

export default Marker;
