import SceneD from "./Scene";
import steps from "./steps";

const IDENTIFIER = "SCENED";

const component = SceneD;

const Scene = {
  IDENTIFIER,
  steps,
  component,
};

export default Scene;
