import {
  addPoints,
  initialStep5Context,
  isLessFish,
  isMoreFish,
  SolutionState,
  Step5Context,
} from "helpers/stateMachineTransistionHelpers";
import { AnyEventObject, createMachine, send } from "xstate";

const registerFishingResult = (
  context: Step5Context,
  event: AnyEventObject
) => {
  context.decisionFish = event.decision;
};

export const StateMachine = createMachine<Step5Context>({
  id: "step5",
  initial: "Intro",
  context: initialStep5Context,
  states: {
    Intro: {
      on: {
        FISH: {
          actions: [registerFishingResult, send("DECISION")],
        },
        DECISION: [
          {
            target: "Correct",
            cond: isLessFish,
            actions: addPoints(600, SolutionState.Correct),
          },
          {
            target: "Wrong",
            cond: isMoreFish,
            actions: addPoints(300, SolutionState.TooMany),
          },
        ],
      },
    },
    Wrong: {
      on: {
        CONTINUE: "Autopilot",
      },
    },
    Autopilot: {
      on: {
        CONTINUE: "Explanation",
      },
    },
    Correct: {
      on: {
        CONTINUE: "Explanation",
      },
    },
    Explanation: {
      type: "final",
    },
  },
});
