import { Link } from "react-router-dom";
import logo from "assets/images/GOJELLY_Logo_v01.svg";

export const NotFound = () => {
  return (
    <div className="not-found">
      <Link to="/">
        <img className="logo" src={logo} alt="Gojelly Logo" />
      </Link>
      <div className="headline">404</div>
      <div className="subline">The page you're looking for does not exist.</div>
    </div>
  );
};
