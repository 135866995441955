import { Link } from "react-router-dom";

export const NavigationLinks = () => {
  return (
    <div className="links">
      <Link className="link" to="/about">
        About
      </Link>
      <Link className="link" to="/imprint">
        Imprint
      </Link>
      <Link className="link" to="/disclaimer">
        Disclaimer
      </Link>
      <Link className="link" to="/contact">
        Contact
      </Link>
    </div>
  );
};
