import { Footer } from "./Footer";
import { Link } from "react-router-dom";
import { NavigationLinks } from "../NavigationLinks";
import { Parallax } from "react-parallax";
import { useCallback, useEffect, useState } from "react";

import logo from "assets/images/GOJELLY_Logo_v01.svg";

import styles from "./OtherSides.module.scss";

interface OtherSideProps {
  backgroundImage: string;
  headerText: string;
  content: React.ReactNode;
}

export const OtherSide = ({
  backgroundImage,
  headerText,
  content,
}: OtherSideProps) => {
  const [parallaxHeight, setParallaxHeight] = useState(0);

  const updateSize = useCallback(() => {
    setParallaxHeight(window.innerHeight * 0.5);
  }, []);

  useEffect(() => {
    if (parallaxHeight === 0) {
      setParallaxHeight(window.innerHeight * 0.5);
    }

    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [parallaxHeight, updateSize]);

  return (
    <div className={styles.outerContainer}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="Gojelly Logo" />
      </Link>
      <div className={styles.links}>
        <NavigationLinks />
      </div>
      <Parallax
        className={styles.headerImage}
        bgImage={backgroundImage}
        strength={parallaxHeight}
      >
        <div className={styles.headerText}>{headerText}</div>
      </Parallax>
      <div className={styles.content}>{content}</div>
      <Footer></Footer>
    </div>
  );
};
