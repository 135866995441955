import { OtherSide } from "./OtherSide";
// Assets
import creditHeader from "assets/images/gojelly_site_bg_04.jpg";
import uniKiel from "assets/images/cau-norm-en-lilagrey-cmyk.png";

import styles from "./OtherSides.module.scss";

export const Contact = () => {
  const content = (
    <div className={styles.contentText}>
      <p>
        <strong>{`SCIENTIFIC PRODUCTION MANAGEMENT`}</strong>
      </p>
      <p>
        Dr. Frederike Tirre <br />
        Center for Ocean and Society <br />
        Neufeldtstr. 10, D-24118 Kiel <br />
        Kiel University <br />
      </p>
      <p>
        <strong>{`SCIENTIFIC CONCEPT`}</strong>
      </p>
      <p>
        Dr. Frederike Tirre <br />
        Dr. Jörn Schmidt <br />
        <br />
        Center for Ocean and Society <br />
        Kiel University <br />
        <br />
        Dr. Jamileh Javidpour <br />
        University of Southern Denmark <br />
      </p>
      <p>
        <strong>{`VISUAL DESIGN`}</strong>
      </p>
      <p>
        Michel Magens <br />
        (Creative Director Visuals, 3D Animation, Sound Compositing) <br />
        <a href={"https://michelmagens.com/"}>https://michelmagens.com/</a>
        <br />
      </p>
      <p>
        <strong>{`SOFTWARE AND GAME DEVELOPMENT`}</strong>
      </p>
      <p>
        naymspace software
        <br />
        <a href={"https://www.naymspace.de/"}>https://www.naymspace.de/</a>
        <br />
        Technical Conception, Development, Game-Design, Game-Play, Storybook,
        Sound-/Music-Selection, Projectmanagement
        <br />
        <br />
        Dennis Nissen, Esther Kathmann, Janina Kwast
      </p>
      <p>
        <strong>{`MUSIC`}</strong>
      </p>
      <p>
        Javier Suarez
        <br />
        <a href={"http://www.betterwithmusic.com/"}>(Jahzzar)</a>
      </p>
      <div className={styles.otherSidesImages}>
        <img
          src={uniKiel}
          className={styles.otherSidesImagesUni}
          alt="logo university of kiel"
        />
      </div>
    </div>
  );

  return (
    <OtherSide
      backgroundImage={creditHeader}
      headerText={"Contact"}
      content={content}
    />
  );
};
