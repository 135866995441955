import { useCallback, useEffect, useState } from "react";
import Typewriter, { TypewriterClass } from "typewriter-effect";
import styles from "./TextTypewriter.module.scss";
import Sound from "react-sound";
import { isMobile } from "react-device-detect";

import tickerSound from "assets/sounds/A_01_01_ticker-loop_v02.mp3";

type BottomScreenTypewriterProps = {
  timesheet: SubtitleTimesheet;
  resetTypeWriter?: boolean;
  setResetTypeWriter?: (val: boolean) => void;
  active?: boolean;
};

export type SubtitleTimesheetEntry = {
  offset?: number; // number of seconds to wait before typing
  waitAfter?: number; // number of seconds to wait after typing the text
  text: string; // text?!
  clear?: boolean; // whether to clear all text after "waitAfter" or not.
};

export type SubtitleTimesheet = Array<SubtitleTimesheetEntry>;

const playlist = (
  typewriter: TypewriterClass,
  timesheet: SubtitleTimesheet,
  setSound: (enable: boolean) => void
) =>
  timesheet.reduce(
    (
      acc: TypewriterClass,
      { offset = 0, waitAfter = 0, text, clear = false }
    ) => {
      typewriter = typewriter
        .pauseFor(offset * 1000)
        .callFunction((typeWriterState) => {
          typeWriterState.elements.cursor.style.display = "inline";
        })
        .callFunction(() => setSound(true))
        .typeString(text)
        .callFunction(() => setSound(false))
        .pauseFor(waitAfter * 1000);
      return clear
        ? typewriter.callFunction((typeWriterState) => {
            typeWriterState.elements.cursor.style.display = "none";
            typeWriterState.elements.wrapper.innerText = "";
          })
        : typewriter;
    },
    typewriter
  );

const playlistRetry = (
  typewriter: TypewriterClass,
  timesheet: SubtitleTimesheet,
  setSound: (enable: boolean) => void
) =>
  timesheet.reduce(
    (
      acc: TypewriterClass,
      { offset = 0, waitAfter = 0, text, clear = false }
    ) => {
      typewriter = typewriter
        .callFunction((typeWriterState) => {
          typeWriterState.elements.cursor.style.display = "none";
          typeWriterState.elements.wrapper.innerText = "";
        })
        .callFunction((typeWriterState) => {
          typeWriterState.elements.cursor.style.display = "inline";
        })
        .pauseFor(offset * 1000)
        .callFunction(() => setSound(true))
        .typeString(text)
        .callFunction(() => setSound(false))
        .pauseFor(waitAfter * 1000);
      return clear
        ? typewriter.callFunction((typeWriterState) => {
            typeWriterState.elements.cursor.style.display = "none";
            typeWriterState.elements.wrapper.innerText = "";
          })
        : typewriter;
    },
    typewriter
  );

const TextTypewriter = ({
  timesheet,
  resetTypeWriter,
  setResetTypeWriter,
  active = true,
}: BottomScreenTypewriterProps) => {
  const [soundEnabled, setSoundEnabled] = useState(false);

  const [lastActiveState, setLastActiveState] = useState(active);

  const [typeWriter, setTypeWriter] =
    useState<TypewriterClass | undefined>(undefined);

  const onInit = useCallback(
    (typewriter: TypewriterClass) => {
      setTypeWriter(typewriter);
      playlist(typewriter, timesheet, setSoundEnabled).start();
    },
    [setSoundEnabled, timesheet]
  );

  useEffect(() => {
    const activeStateChanged = () => {
      return lastActiveState !== active;
    };
    if (typeWriter && activeStateChanged()) {
      if (!active) {
        typeWriter.stop();
        setLastActiveState(false);
      } else {
        typeWriter.start();
        setLastActiveState(true);
      }
    }
  }, [active, typeWriter, lastActiveState]);

  useEffect(() => {
    if (resetTypeWriter && typeWriter) {
      playlistRetry(typeWriter, timesheet, setSoundEnabled).start();
      if (setResetTypeWriter) {
        setResetTypeWriter(false);
      }
    }
  }, [
    resetTypeWriter,
    setTypeWriter,
    setResetTypeWriter,
    typeWriter,
    timesheet,
  ]);

  return (
    <>
      {soundEnabled && !isMobile && active && (
        <Sound url={tickerSound} playStatus={"PLAYING"} loop={true} />
      )}
      <Typewriter
        onInit={onInit}
        options={{
          delay: 10,
          deleteSpeed: 0,
          wrapperClassName: styles.typewriter,
        }}
      />
    </>
  );
};

export default TextTypewriter;
