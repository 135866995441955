import React from "react";

import styles from "./Layout.module.scss";
import BottomButtonBar from "components/ui/layout/BottomButtonBar";

type VideoSceneLayoutProps = {
  topContent?: JSX.Element;
  bottomContent?: JSX.Element;
  buttonBarContent?: JSX.Element;
};

function TypewriterAndButtonsOverlay({
  topContent,
  bottomContent,
  buttonBarContent,
}: VideoSceneLayoutProps) {
  return (
    <div className={styles.typewriterAndButtonsLayout}>
      <div className={styles.topContainer}>{topContent}</div>
      <div className={styles.bottomContainer}>{bottomContent}</div>
      <BottomButtonBar>{buttonBarContent}</BottomButtonBar>
    </div>
  );
}

export default TypewriterAndButtonsOverlay;
