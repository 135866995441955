import { SolutionState } from "helpers/stateMachineTransistionHelpers";
import { useContext } from "react";
import { WrongStep } from "components/prefabs/WrongStep";
import StateMachineContext from "lib/StateMachineContext";
import TextTypewriter from "components/ui/TextTypewriter";
import { useTranslation } from "react-i18next";
// Assets
import video from "assets/scenes/D_01/D_05_02_bg.mp4";

const Wrong = () => {
  const { state } = useContext(StateMachineContext);
  const solutionState = state?.context.solutionState;
  const scenePoints = state?.context.pointsScene;

  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      timesheet={[
        {
          offset: 0,
          text: `${
            solutionState === SolutionState.TooMany
              ? scenePoints === 300
                ? t("sceneD.step5.wrong.totallyWrongTooMany")
                : t("sceneD.step5.wrong.wrongTooMany")
              : scenePoints === 300
              ? t("sceneD.step5.wrong.totallyWrongTooFew")
              : t("sceneD.step5.wrong.wrongTooFew")
          }`,
        },
      ]}
    />
  );

  return (
    <WrongStep
      subline={`${t("general.wrongSolution")}, ${
        solutionState === SolutionState.TooMany
          ? t("general.tooMany")
          : t("general.tooFew")
      }`}
      typeWriter={typewriter}
      video={video}
    />
  );
};

export default Wrong;
