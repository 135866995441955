import { MouseEvent, useCallback } from "react";
import { useClick } from "components/ClickProvider";
import { Button } from "./Button";
// Assets
import playButton from "assets/images/ui/A_05_01_play.png";
import pauseButton from "assets/images/ui/A_05_01_pause.png";

type Props = {
  onClick: (e: MouseEvent) => void;
  isVideoPaused: boolean;
};

export const PlayOrPauseButton = ({ onClick, isVideoPaused }: Props) => {
  const click = useClick();

  const onClickPause = useCallback(
    (event: MouseEvent) => {
      click();
      onClick(event);
    },
    [click, onClick]
  );

  return (
    <Button onClick={onClickPause}>
      {isVideoPaused ? (
        <img src={playButton} alt="button to play" />
      ) : (
        <img src={pauseButton} alt="button to pause" />
      )}
    </Button>
  );
};
