import React from "react";
import { AnyEventObject, EventData, SCXML, SingleOrArray, State } from "xstate";

export type ContextProps = {
  state: State<any> | null;
  send:
    | ((
        event: SingleOrArray<any> | SCXML.Event<AnyEventObject>,
        payload?: EventData | undefined
      ) => State<any>)
    | null;
};

const StateMachineContext = React.createContext<ContextProps>({
  state: null,
  send: null,
});

export default StateMachineContext;
