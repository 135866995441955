import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import GameManager from "GameManager";
import { SceneDecision, ScenePoints } from "types";

interface InitialState {
  currentScene: string;
  currentStep: number;
  decisions: { [key: string]: { sliderName: string; decision: number }[] };
  points: { [key: string]: number };
}

const initialState: InitialState = {
  currentScene: GameManager.firstScene,
  currentStep: 0,
  decisions: {},
  points: {
    total: 0,
    SCENEA: 0,
    SCENEB: 0,
    SCENEC: 0,
    SCENED: 0,
  },
};

const GameSlice = createSlice({
  name: "Game",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setScene: (state, action: PayloadAction<string>) => {
      state.currentScene = action.payload;
      state.currentStep = initialState.currentStep;
    },
    resetSteps: (state) => {
      state.currentStep = initialState.currentStep;
    },
    registerDecision: (state, action: PayloadAction<SceneDecision>) => {
      state.decisions[action.payload.scene] = action.payload.decision;
    },
    registerPoints: (state, action: PayloadAction<ScenePoints>) => {
      state.points[action.payload.scene] = action.payload.points;
      state.points.total = state.points.total + action.payload.points;
    },
    resetPoints: (state) => {
      state.points = {
        total: 0,
        SCENEA: 0,
        SCENEB: 0,
        SCENEC: 0,
        SCENED: 0,
      };
    },
  },
});

export const {
  resetSteps,
  setStep,
  setScene,
  registerDecision,
  registerPoints,
  resetPoints,
} = GameSlice.actions;
export default GameSlice.reducer;
