import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player/lazy";

import style from "./FullscreenVideo.module.scss";
import { isIOS } from "react-device-detect";
import ClipLoader from "react-spinners/ClipLoader";

type VideoProps = {
  video: string;
  onVideoEnded?: () => void;
  resetCount?: number;
  onProgress?: {
    callback: (shouldBeVisible: boolean) => void;
    seconds: number;
  };
  children?: React.ReactNode;
  videoIsPaused?: boolean;
  setVideoIsReady?: (val: boolean) => void;
  videoIsReady?: boolean;
};

export const playerContext =
  React.createContext<RefObject<ReactPlayer> | null>(null);

const FullscreenVideo = ({
  video,
  onVideoEnded,
  resetCount = 0,
  onProgress,
  children,
  videoIsPaused,
  setVideoIsReady,
  videoIsReady,
}: VideoProps) => {
  const [lastResetCount, setLastResetCount] = useState(0);
  const playerRef = useRef<ReactPlayer>(null);
  const player = playerRef.current;

  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    if (resetCount !== lastResetCount) {
      const resetVideo = () => {
        player?.seekTo(0);
      };
      resetVideo();
      setLastResetCount(resetCount);
    }
  }, [resetCount, player, lastResetCount]);

  const onVideoProgress = useCallback(
    (state: {
      played: number;
      playedSeconds: number;
      loaded: number;
      loadedSeconds: number;
    }) => {
      if (onProgress && state.playedSeconds >= onProgress.seconds) {
        onProgress.callback(true);
      }
    },
    [onProgress]
  );

  useEffect(() => {
    if (videoIsPaused) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [videoIsPaused]);

  const onReady = useCallback(() => {
    if (setVideoIsReady) setVideoIsReady(true);
  }, [setVideoIsReady])

  return (
    <div className={style.videoContainer}>
      {videoIsReady === false && (
        <ClipLoader
          color={"#4daee6"}
          loading={true}
          css={
            "position: absolute; top: calc(50% - 25px); left: calc(50% - 25px); z-index: 1000"
          }
          size={50}
        />
      )}
      <ReactPlayer
        className={style.fullscreenVideo}
        url={video}
        width="50%"
        height="100%"
        playing={isPlaying}
        onEnded={onVideoEnded}
        ref={playerRef}
        playsinline
        muted={isIOS}
        loop={false}
        onProgress={onVideoProgress}
        onReady={onReady}
      />
      <playerContext.Provider value={playerRef}>
        {children}
      </playerContext.Provider>
    </div>
  );
};

export default FullscreenVideo;
