import { IntroStep } from "components/prefabs/IntroStep";
import { useEffect, useState } from "react";
import Input from "./Input";
import TextTypewriter from "components/ui/TextTypewriter";
import { useTranslation } from "react-i18next";
// Assets
import video from "assets/scenes/C_01/C_05_01_bg.mp4";
import explanationImage from "assets/scenes/C_01/C_05_06_grafik.png";

const Intro = () => {
  const [showInput, setShowInput] = useState(false);
  const [shouldResetCount, setShouldResetCount] = useState(false);
  const [typewriterActive, setTypewriterActive] = useState(true);

  useEffect(() => {
    const explImg = new Image();
    explImg.src = explanationImage;
  }, []);

  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      active={typewriterActive}
      resetTypeWriter={shouldResetCount}
      setResetTypeWriter={setShouldResetCount}
      timesheet={[
        {
          offset: 2,
          text: t("sceneC.step5.intro.first"),
          waitAfter: 4.5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneC.step5.intro.second"),
          waitAfter: 5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneC.step5.intro.third"),
          waitAfter: 6,
          clear: true,
        },
      ]}
    />
  );

  useEffect(() => {
    if (shouldResetCount) {
      setShowInput(false);
    }
  }, [shouldResetCount]);

  return (
    <IntroStep
      input={<Input setShouldResetCount={setShouldResetCount} />}
      secondsForInput={26}
      showInput={showInput}
      setInputVisible={setShowInput}
      typewriter={typewriter}
      video={video}
      shouldResetCount={shouldResetCount}
      setShouldResetCount={setShouldResetCount}
      setTypewriterActive={setTypewriterActive}
    />
  );
};

export default Intro;
