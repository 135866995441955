import SceneC from "./Scene";
import steps from "./steps";

const IDENTIFIER = "SCENEC";

const component = SceneC;

const Scene = {
  IDENTIFIER,
  steps,
  component,
};

export default Scene;
