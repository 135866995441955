import { useCallback } from "react";
import { useClick } from "components/ClickProvider";
import ReactSlider from "react-slider";

import styles from "./DecisionSlider.module.scss";

type DecisionSliderProps = {
  onSetValue: (value: number) => void;
  textLess: string;
  textMore: string;
};

const DecisionSlider = ({
  onSetValue,
  textLess,
  textMore,
}: DecisionSliderProps) => {
  const click = useClick();

  const setSliderValue = useCallback(
    (value: number) => {
      click();
      onSetValue(value);
    },
    [onSetValue, click]
  );

  return (
    <div className={styles.decision}>
      <div className={styles.before}>{textLess}</div>
      <ReactSlider
        className={styles.slider}
        min={-1}
        max={1}
        thumbClassName={styles.thumb}
        trackClassName={styles.track}
        onChange={setSliderValue}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
      />
      <div className={styles.after}>{textMore}</div>
    </div>
  );
};

export default DecisionSlider;
