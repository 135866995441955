import { OtherSide } from "./OtherSide";
// Assets
import imprintImage from "assets/images/gojelly_site_bg_02.jpg";

import styles from "./OtherSides.module.scss";

export const Imprint = () => {
  const content = (
    <div className={styles.contentText}>
      <p>
        <strong>{`IMPRINT`}</strong>
      </p>
      <p>
        according to § 5 TMG
        <br />
        Operator of the website
      </p>
      <p>
        Christian-Albrechts-Universität zu Kiel
        <br />
        Christian-Albrechts-Platz 4<br />
        24118 Kiel, Germany
      </p>
      <p>
        Contact: <br />
        Dr. Frederike Tirre
        <br />
        Center for Ocean and Society
        <br />
        <a href="mailto:ftirre@uv.uni-kiel.de">ftirre@uv.uni-kiel.de</a>
        <br />
        +49 431 880-6700
      </p>
      <p>
        <strong>{`LICENCES`}</strong>
      </p>
      <p>
        Music:
        <br />
        <a href={"http://www.betterwithmusic.com/"}>Jahzarr</a>
        <br />
        <ul>
          <li>
            {`from the album Ashes, all songs under Creative Commons licence `}
            <a href="https://creativecommons.org/licenses/by-sa/4.0/">
              CC BY-SA
            </a>
            {`, Fair (Level A + E), Blueprint (Level A), Retour and Zodiac (Level B), Paved Sky and Landscape (shortened) (Level C)`}
          </li>
          <li>
            {`from the album Super, all songs under Creative Commons licence `}
            <a href="https://creativecommons.org/licenses/by-sa/4.0/">
              CC BY-SA 4.0
            </a>
            {`, Chiefs (Level D), Endeavour (shortened, Level E), Comedie (shortened and complete, Level E)`}
          </li>
        </ul>
      </p>
      <p>
        Fonts:
        <ul>
          <li>
            {`"Dimbo”, by `}
            <a href="https://www.dafont.com/jayvee-d-enaguas.d2725">
              Jayvee Enaguas
            </a>
            {` under licence by Creative Commons `}
            <a href="https://creativecommons.org/licenses/by-sa/3.0/">
              CC-BY-SA 3.0
            </a>
            {`, source `}
            <a href="https://www.dafont.com/de/dimbo.font">dafont</a>
          </li>
          <li>
            {`"Roboto”, source `}
            <a href="http://www.fonts100.com">free font</a>
          </li>
        </ul>
      </p>
      <p>
        Illustrations:
        <ul>
          <li>
            {`White-Spotted Jellyfish `}
            <a href="https://en.wikipedia.org/wiki/User:Papa_Lima_Whiskey">
              Papa Lima Whiskey at English Wikipedia,
            </a>
            {` under licence by Creative Commons `}
            <a href="https://creativecommons.org/licenses/by-sa/3.0/">
              CC-BY-SA 3.0
            </a>
          </li>
          <li>
            {`Moon Jellyfish `}
            <a href="https://commons.wikimedia.org/wiki/User:Alex.vasenin">
              Alexander Vasenin
            </a>
            {` under licence by Creative Commons `}
            <a href="https://creativecommons.org/licenses/by-sa/3.0/">
              CC-BY-SA 3.0
            </a>
          </li>
          <li>
            {`Flame Jellyfish `}
            <a href="https://www.flickr.com/photos/wbaiv/21079330589/in/album-72157625679021947/">
              Bill Abbott - aDSC_0786
            </a>
            {` under licence by Creative Commons `}
            <a href="https://creativecommons.org/licenses/by-sa/2.0">
              CC BY-SA 2.0
            </a>
          </li>
          <li>
            {`Eutrophication `}
            <a href="https://commons.wikimedia.org/w/index.php?title=User:Kungfucrab&action=edit&redlink=1">
              Kungfucrab
            </a>
            {` under licence by Creative Commons `}
            <a href="https://creativecommons.org/licenses/by-sa/4.0">
              CC BY-SA 4.0
            </a>
          </li>
        </ul>
      </p>
      <p>
        Sounds:
        <ul>
          <li>
            {`All sounds under licence by `}
            <a href="https://elements.envato.com/">envato elements</a>
          </li>
        </ul>
      </p>
    </div>
  );

  return (
    <OtherSide
      backgroundImage={imprintImage}
      headerText={"Imprint"}
      content={content}
    />
  );
};
