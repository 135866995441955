import { playerContext } from "components/video/FullscreenVideo";
import React, {
  FunctionComponent,
  MouseEvent,
  useContext,
  useEffect,
} from "react";

import styles from "./Button.module.scss";

type Props = {
  onClick: (e: MouseEvent) => void;
};

export const Button: FunctionComponent<Props> = ({ onClick, children }) => {
  const repeatButtonRef = React.createRef<HTMLButtonElement>();

  const currentPlayerContext = useContext(playerContext);

  useEffect(() => {
    if (repeatButtonRef.current) {
      repeatButtonRef.current.addEventListener("click", () => {
        currentPlayerContext?.current?.getInternalPlayer().play();
      });
    }
  }, [currentPlayerContext, repeatButtonRef]);
  return (
    <button ref={repeatButtonRef} className={styles.button} onClick={onClick}>
      {children}
    </button>
  );
};
