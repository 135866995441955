import { configureStore } from "@reduxjs/toolkit";
import MapSlice from "components/map/MapSlice";
import GameSlice from "GameSlice";
import SoundSlice from "SoundSlice";

export const store = configureStore({
  reducer: {
    game: GameSlice,
    map: MapSlice,
    sound: SoundSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
