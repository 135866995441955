import { sceneBackgroundMusicEnabled } from "SoundSlice";
import { StepComponent } from "types";
import { useAppDispatch } from "hooks";
import { useCallback, useEffect, useState } from "react";
import GameManager from "GameManager";
import SimpleVideoStep from "components/prefabs/SimpleVideoStep";
import TextTypewriter from "components/ui/TextTypewriter";
import { useTranslation } from "react-i18next";
// Assets
import video from "assets/scenes/C_01/C_06_01_bg.mp4";
import mapImage from "assets/scenes/C_01/C_01_01_map.jpg";

export const mapBackground: string = mapImage;

const Step6: StepComponent = () => {
  const [resetTypeWriter, setResetTypeWriter] = useState(false);
  const [typewriterActive, setTypewriterActive] = useState(true);

  const onClickContinue = useCallback(() => {
    GameManager.next();
  }, []);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(sceneBackgroundMusicEnabled(true));
    };
  }, [dispatch]);

  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      active={typewriterActive}
      resetTypeWriter={resetTypeWriter}
      setResetTypeWriter={setResetTypeWriter}
      timesheet={[
        {
          offset: 1,
          text: t("sceneC.step6.first"),
          waitAfter: 3.5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneC.step6.second"),
          waitAfter: 4.5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneC.step6.third"),
        },
      ]}
    />
  );
  return (
    <SimpleVideoStep
      setResetTextTypeWriter={setResetTypeWriter}
      video={video}
      onClickContinue={onClickContinue}
      content={{ position: "bottom", element: typewriter }}
      setTypewriterActive={setTypewriterActive}
    />
  );
};

const exports = { component: Step6, marker: [], mapBackground };

export default exports;
