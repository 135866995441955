import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          startpage: {
            title: "Save the World! *",
            subline: "* or at least Sunset-City",
          },
          general: {
            correct: "CORRECT SOLUTION",
            pointsAchieved: "You achieved {{points}} from 600 points.",
            autopilot: "RECOMMENDATION",
            slider: {
              moreFishing: "more fishing",
              lessFishing: "less fishing",
              moreAgriculture: "more agriculture",
              lessAgriculture: "less agriculture",
              moreFactories: "more burger factories",
              lessFactories: "less burger factories",
              moreFertilizer: "less jellyfish fertilizer",
              lessFertilizer: "more jellyfish fertilizer",
              moreAquaCulture: "more aquaculture",
              lessAquaCulture: "less aquaculture",
            },
            tooMany: "TOO MANY JELLYFISH",
            tooFew: "TOO FEW JELLYFISH",
            wrongSolution: "WRONG SOLUTION",
          },
          sceneA: {
            step0: {
              first:
                "Sunset City is a true gem at the Gulf of Azuria. The city is growing steadily, the surrounding fields are fertile, and industry is doing well.",
              second:
                "More and more tourists come here to swim in the bright blue sea and to marvel at the wonderful underwater world while snorkeling and diving.",
              third:
                "The best prerequisites to do your job well. Because you are the mayor of Sunset City.",
              fourth:
                "Suddenly a warning message appears on your screen: Onshore winds are causing turmoil and chaos on the beach.",
            },
            step1:
              "A huge population of jellyfish is pushed into Sunset City Bay by onshore winds.",
            step2: {
              first:
                "The beaches are in danger, first tourists have cancelled their bookings. You must act, mayor.",
              second:
                "Fortunately, you invested in a new maritime research institute two years ago. You call in the chief scientist.",
              third:
                "The research team sets sail on the MS Science Rocks to survey the situation.",
            },
            step3: {
              first:
                'Chief Scientist: "That looks a lot like a jellyfish bloom to me."',
              second:
                'Mayor: "Whatever it is - I need you to help me keep it off my beaches."',
              third:
                'Chief Scientist: "Give us two weeks, Mayor. By then we will provide you with initial research results."',
            },
            step4:
              "Two weeks?! That may be quick for a scientist, but if you don't act now, the tabloids will rip you apart.",
            step5: {
              intro:
                "The only thing that comes to your mind in these times of need is your fishing fleet off the coast.",
              input: "What are you going to do to stop the jellyfish?",
              correct:
                "Good news, we've been looking at the life cycle of jellyfish. And I have to say, the citizens of Sunset City made you mayor for a reason: Your decision to allow less fishing was exactly right. Many fish feed on the jellyfish, making them their natural predators.",
              wrong: {
                explanation:
                  "We looked at the life cycle of the jellyfish. The bad news, your decision to allow more fishing was the wrong one. Many fish feed on the jellyfish. And those are the very predators you've now fished out.",
              },
              autopilot:
                "To decimate the jellyfish in the longer term we need more predators, so more fish and correspondingly less fishing.",
              explanation: {
                headline: "Life cycle jellyfish",
                list1: "Jellyfish medusa releases eggs and sperm.",
                list2: "Jellyfish larva floats in seawater.",
                list3: "Larva settles on hard substrate and becomes polyp.",
                list4:
                  "Polyps grow, elongate and reproduce many young medusae.",
                list5: "Young jellyfish mature and the cycle begins again.",
              },
            },
            step6: {
              first:
                "With the help of scientists, you've prevented Sunset City Bay from being infested with flabby jellyfish.",
              second:
                "The beaches can be used again and the number of tourists is increasing.",
              third:
                "The citizens are reassured, it's delightful in your city and a new residential area is emerging.",
            },
          },
          sceneB: {
            step1:
              "The situation seems to have calmed down. But then you receive a worrying message from your Ministry of Supply.",
            step2: {
              first:
                "The first supermarkets no longer have enough vegetables on the shelves.",
              second: "It's no different with fish. Fishermen complain:",
              third:
                '"You want fish? Then stop the politicians with their ridiculous fish quotas!"',
              fourth:
                '"To get rid of the jellyfish is not that easy. The water temperature is rising at the moment and jellyfish love that. They are multiplying at a furious rate."',
              fifth:
                "Hoarding purchases cause conflict among Sunset City residents.",
            },
            step3: {
              first: "You're looking for help at the research institute.",
              second:
                "The scientists are searching for a solution to the currently restricted fishing quotas.",
              third:
                "At the same time, they are investigating methods to keep the jellyfish population controlled.",
              fourth: "Surely there must be a feasible way to use them?",
              fifth: 'Mayor: "And that works?"',
              sixth:
                'Chief Scientist: "We were able to extract a substrate from the watery jellyfish meat with the help of an Italian gourmet chef."',
              seventh: '"Try this burger..."',
            },
            step4:
              "You're all stoked with excitement. And set up your first jellyfish burger factory.",
            step5: {
              intro: {
                first:
                  "The production of jellyfish burgers has benefits in every way.",
                second:
                  "Flooded with positive energy, you check on the construction site.",
              },
              input: "What do you want to do to ensure a constant food supply?",
              correct: {
                correct:
                  '"Bravo, Mayor! You have succeeded in achieving a balanced ratio. You already knew the influence of fishing on jellyfish growth. By building the burger factories you now have buyers for the animals. You did a good job of incorporating that into your considerations."',
                quiteCorrect:
                  '"Mayor, quite amazing how well you are informed about the different factors. There is still room for improvement, but that\'s a whole different kettle of fish."',
              },
              wrong: {
                totallyWrongTooMany:
                  "Mayor, unfortunately you have not been able to get the balance right. You already know the influence of fishing on jellyfish growth. However, due to the construction of the burger factories, you now have customers for the animals. You should have included that into better in your considerations.",
                wrongTooMany:
                  "Mayor, unfortunately you have not been able to get the balance right. There are way to many jellyfish now; you have not used your resources wisely.",
                totallyWrongTooFew:
                  "Mayor, unfortunately you have not been able to get the balance right. You already know the influence of fishing on jellyfish growth. However, due to the construction of the burger factories, you now have customers for the animals. You should have included that into better in your considerations.",
                wrongTooFew:
                  "Mayor, unfortunately you have not been able to strike a balance. Your production requires more jellyfish than you have available. Try to choose more wisely next time.",
              },
              autopilot:
                "We have calculated for you the optimal ratio of farms, fisheries, and burger factories. We would advise you to implement our recommendations in the upcoming months.",
              explanation: {
                headline: "Jellyfish as Food",
                headline1: "Popular Eatable Jellyfishes:",
                list1:
                  "1. White-Spotted Jellyfish 2. Moon Jellyfish 3. Flame Jellyfish",
                headline2: "Jellyfish Producers:",
                list2:
                  "China, Indonesia, Korea, Malaysia, Myanmar, Philippines, Thailand",
                headline3: "Jellyfish Consumers:",
                list3:
                  "China, Korea, Taiwan, Vietnam, Japan, Europe [starting], USA, New, Australia Zealand",
              },
            },
            step6: {
              first: "Your city is developing magnificiently.",
              second:
                "The burgers from the jellyfish burger factory are selling like hot cakes.",
              third: "You have found a good measure in fishing.",
              fourth:
                "Satisfied, you look at the many fields that are now cultivated by farmers in the estuary.",
            },
          },
          sceneC: {
            step1:
              "But what is this? You don't like the color of the water at all. It seems that something is being washed into the sea by the river. You ask your scientists for advice.",
            step2: {
              first:
                "The scientists cast off with the MS Sciencerocks and sail into the estuary.",
              second:
                "They quickly take water samples and analyze them on the spot.",
            },
            step3: {
              first:
                'Chief scientist: "Mayor, there is no question: The sea is in danger. Too much fertilizer is being spread onto the fields in the form of liquid manure."',
              second:
                '"The soil is oversaturated, it can no longer absorb the nutrients. Nitrogen and phosphorus compounds end up in the sea."',
              third:
                '"For most marine life this is a problem, but the jellyfish feel comfortable in this environment."',
              fourth: 'Mayor: "What do we do now?"',
              fifth:
                'Chief scientist: "We could manufacture biological fertilizer from the jellyfish. We\'re relatively far along in researching this possible use."',
              sixth:
                '"Mayor, we should take a chance and set up a jellyfish fertilizer factory."',
            },
            step4:
              "You agree and build your first jellyfish fertilizer factory.",
            step5: {
              intro: {
                first:
                  "The jellyfish fertilizer factory allows for another option to find the perfect balance for your city.",
                second:
                  "You can provide your citizens and guests with enough vegetables and fruits while keeping the jellyfish in the bay in check.",
                third:
                  "If you provide jellyfish fertilizer to agriculture, yields will remain stable, but fewer nutrients will wash into the sea.",
              },
              inputs:
                "What will you do to prevent overfertilization of your bay?",
              correct: {
                correct:
                  '"Great! You\'ve achieved an optimal balance of farms, fisheries and burger factories. And with the help of our new Jelly Grow fertilizer, you were able to prevent over-fertilization and still increase yields."',
                quiteCorrect:
                  '"Mayor, pretty amazing how well you are informed about the different factors. There is still room for improvement, but that\'s a whole different kettle of fish."',
              },
              wrong: {
                totallyWrongTooMany:
                  '"Mayor, unfortunately you have not succeeded in achieving a good balance. The fish population suffers from over-fertilization, while the jellyfish can multiply rapidly. Thus, your fishermen have no income and tourism is threatened."',
                wrongTooMany:
                  '"Mayor, unfortunately you have not been able to get the balance right. There are way to many jellyfish now; you have not used your resources wisely."',
                totallyWrongTooFew:
                  '"The new fertilizer made from jellyfish is truly outstanding. Hardly any nutrients are washed into the sea. That is good for all marine life. Almost all of it: The number of jellyfish has decreased rapidly. What shall the burgers be made of now? Mayor, that\'s not the right mixture of choices."',
                wrongTooFew:
                  '"Mayor, unfortunately you have not been able to strike a balance. Your production requires more jellyfish than you have available. Try to choose more wisely next time."',
              },
              autopilot:
                "We need to learn from our mistakes - and act quickly. Just don't lose your head. Our analysis of the situation in the bay and the research findings of colleagues in Japan allow us to draw concrete conclusions. Implement these recommendations directly.",
              explanation: {
                headline: "Overfertilization",
                first: "Fertilizer is applied to soil.",
                second: "Nutrients get into soil and drain to surface water.",
                third: "Nutrient wash over land into seawater.",
                fourth: "Excess nutrients causes algal bloom.",
                fifth: "Algal bloom reduces light penetration from the sun.",
                sixth:
                  "Plants and organisms beneath algal bloom die because they cannot photosynthesize.",
                seventh:
                  "Algal bloom dies and sinks to the bottom, bacteria start decomposing organic material using oxygen.",
                eighth:
                  "Decomposition processes deplete oxygen which is essential for larger life forms, such as fish.",
              },
            },
            step6: {
              first:
                "The threat of losing your beautiful coastline is banished.",
              second: "Nutrient concentrations are kept in check.",
              third:
                "Thanks to the new fertilizer made from jellyfish, farm yields are good.",
            },
          },
          sceneD: {
            step1:
              "There are new problems: The fertilizer plant and the burger factory need more jellyfish, because the demand is increasing every day. Hopefully the researchers will come up with something.",
            step2: {
              first: "You make your way to the research station.",
              second:
                "It wouldn't be the first time your chief scientist has bailed you out.",
              third:
                'Chief Scientist: "You\'re just in time, Mayor. Follow me."',
              fourth:
                '"Congratulation. By now you\'ve created a veritable jellyfish industry and you are using the jellyfish in a variety of ways."',
              fifth:
                '"But as we can currently see, the success of this industry is highly dependent on the development of jellyfish in the sea."',
            },
            step3: {
              first:
                'Chief Scientist: "Let me show you our new achievement. It will help you balance the jellyfish demand."',
              second: '"We have found a way to grow jellyfish."',
              third:
                '"With aquaculture, you are independent of the whims of nature. Just think of the impact that a decrease in water temperature would have on jellyfish abundance."',
              fourth:
                '"You should move at least some of your jellyfish production onshore, Mayor."',
            },
            step4:
              "The jellyfish shortage must not remain a permanent condition. You trust your crew of scientists and establish an aquaculture for jellyfish farming on land.",
            step5: {
              intro: {
                first:
                  'Chief Scientist: "It may sound crazy to you, Mayor. First we try to get rid of jellyfish and now we build expensive facilities to farm jellyfish on land."',
                second:
                  '"But remember, Mayor, that makes you independent of the interferences of the sea. You have to balance jellyfish removal and production."',
                third:
                  '"If you provide jellyfish fertilizer to agriculture, yields will remain stable, but fewer nutrients will wash into the sea."',
              },
              inputs:
                "What will you do to end the jellyfish shortage without risking a jellyfish plague?",
              correct: {
                correct:
                  '"Mayor, you never cease to amaze me. To reconcile these many different factors... that is an almost inhuman task. And you have succeeded. You\'ve earned yourself a vacation."',
                quiteCorrect:
                  '"Mayor, quite amazing how well you are informed about the different factors. There is still room for improvement, but you\'ve earned yourself a vacation for sure."',
              },
              wrong: {
                totallyWrongTooMany:
                  '"Mayor, unfortunately you have not been able to get the balance right. The combination of aquaculture and fishing is too much, now you do have too many jellyfish: The prices for jellyfish are at their lowest and fishermen prefer to catch other marine animals that make them more money. There are too many jellyfish in the sea again, the first specimens are lying on the beach."',
                wrongTooMany:
                  '"Mayor, unfortunately you have not been able to get the balance right. There are way to many jellyfish now; you have not used your ressources wisely."',
                totallyWrongTooFew:
                  '"Mayor, unfortunately you have not been able to strike a balance.  Farmers are excited about jellyfish fertilizer, people love your burgers, but not enough jellyfish have been collected to manufacture those products. There are no  yields from aquaculture."',
                wrongTooFew:
                  '"Mayor, unfortunately you have not been able to strike a balance. Your production requires more jellyfish than you have available. Try to choose more wisely next time."',
              },
              autopilot:
                '"Mayor, thanks to your recent investment, we were able to purchase the high-performance Q-Rvastic computer. It has calculated the perfect ratio of all factors. Once you\'ve implemented that, you really need to take a vacation. You look tired."',
              explanation: {
                text: "A jellyfish aquarium tries to imitate the ocean current, without which jellyfish cannot move. It has no corners or edges where the jellyfish could hurt themselves. The aquarium has a rounded shape, creating a circular flow. This way, the jellyfish always float gently in a circle without sticking to the bottom. A jellyfish aquarium consists of a water inlet and outlet,a pump, a filter compartment and LED light.",
                headline: "Jellyfish aquarium",
              },
            },
          },
          sceneE: {
            step1: {
              first:
                "You have taken your scientist's advice to heart – you are treating yourself with a week's vacation on the most beautiful coast far and wide.",
              second: "At the beautiful beaches of Sunset City, of course.",
              third: "Yet somehow you cannot relax.",
              fourth: "You call off your vacation.",
              fifth:
                "Back at your desk, the report from your favorite research institute has been waiting for you for a few days.",
              sixth:
                "You are about to open it when suddenly Suncity's warning system hits the alarm.",
              seventh:
                "The tourism rolling in has left its mark: The sea is full of microplastics. Even in the drinking water, the first particles have been detected.",
              eigth:
                "A disaster - because Sunset City is the drinking water reservoir for the whole country. Humans and animals take up harmful microplastics.",
            },
            step2: {
              first:
                "Microplastic is formed when larger pieces of plastic in the ocean are broken down into smaller and smaller pieces by wind and wave action.",
              second:
                "It is also created by tire abrasion, clothing abrasion when washing clothes and is a component of many cosmetic products such as creams and scrubs.",
              third:
                "Because microplastic or nanoplastic is too small to be filtered by sewage treatment plants, the particles end up in the water. This way microplastic ends up in the ocean and in our wastewater.",
            },
            step3: {
              first: 'Mayor: "Mr. President, what are you doing here?"',
              second:
                'President: "Mayor, have you lost your mind?! Rising population and booming tourism in your small town, then cars everywhere, tons of garbage in the sea and now microplastic in the drinking water..."',
              third:
                '"Our entire country depends on water from Sunset City. I won\'t tolerate one nanoparticle of plastic in our drinking water. What you are doing is at the expense of the ocean! And at the expense of the people of our beautiful country..."',
              fourth:
                '"I\'m giving you an ultimatum, Mayor. Put a solution on the table for me, or you have been mayor of Sunset City quite long enough."',
            },
            step4: {
              first: "What's that? Oh, the research report.",
              second:
                "To distract yourself, you start reading the chief scientist's letter.",
              third:
                '"Dear Mr. Mayor, you have always believed in us and supported us, ..."',
              fourth:
                '"... so on behalf of the entire research team, I am pleased to be one of the first to tell you about our absolutely sensational discovery ..."',
              fifth:
                '"Let me explain it to you like this: Jellyfish secrete mucus. This has a protective function and repels enemies, foreign particles and microorganisms ..."',
              sixth:
                '"And that is the key point - jellyfish mucus has fascinating properties: The hydrogel it contains is capable of binding microparticles, even the smallest nanoparticles ..."',
              seventh:
                '"See our drawing of a innovative filter system: When the particles bind with the hydrogel, clumps are formed. These can then be easily filtered out of the water ..."',
              eigth:
                '"Sunset City\'s wastewater treatment plants could easily be upgraded with this technology and would then be much more effective. We call the invention Jelly Pure."',
              nineth: "Eureka, this is a salvation!",
              tenth:
                "We can use it to filter the microplastic out of our drinking water! These scientists are simply brilliant!",
            },
            step5: {
              first:
                "You get into your car and race to the research institute.",
              second: 'Mayor: "You saved me - and Sunset City!',
            },
            end: {
              headlineGood: "You've done it!",
              headlineBad: "You've done it!*",
              subline: "* but you can do better!",
              informationGood:
                "Congratulations, you know how to save Sunset-City.",
              informationBad:
                "Well, without your scientists you would have been quite lost.",
              points: "You achieved {{points}} from 2400 points.",
            },
          },
          // here we will place our translations...
        },
      },
      de: {
        translation: {
          startpage: {
            title: "Rette die Welt! *",
            subline: "* oder zumindest Sunset-City",
          },
          general: {
            correct: "RICHTIGE ANTWORT",
            pointsAchieved: "Du hast {{points}} von 600 Punkten erreicht.",
            autopilot: "EMPFEHLUNG",
            slider: {
              moreFishing: "Mehr Fischfang",
              lessFishing: "Weniger Fischfang",
              moreAgriculture: "MEHR LANDWIRTSCHAFT",
              lessAgriculture: "WENIGER LANDWIRTSCHAFT",
              moreFactories: "MEHR BURGERFABRIKEN",
              lessFactories: "WENIGER BURGERFABRIKEN",
              moreFertilizer: "MEHR QUALLENDÜNGEREINSATZ",
              lessFertilizer: "WENIGER QUALLENDÜNGEREINSATZ",
              moreAquaCulture: "VIEL AQUAKULTUR",
              lessAquaCulture: "WENIGER AQUAKULTUR",
            },
            tooMany: "ZU VIELE QUALLEN",
            tooFew: "ZU WENIG QUALLEN",
            wrongSolution: "FALSCHE ANTWORT",
          },
          sceneA: {
            step0: {
              first:
                "Sunset City ist ein wahres Kleinod am Golf von Azurien. Stetig wächst die Stadt, die Äcker im Umland sind fruchtbar und der Wirtschaft geht es gut.",
              second:
                "Immer mehr Touristen kommen hierher, um im strahlend blauen Meer zu baden und beim Schnorcheln und tauchen die wunderbare Unterwasserwelt zu bestaunen.",
              third:
                "Beste Vorraussetzungen, um Deinen Job gut zu machen. Denn Du bist Bürgermeister von Sunset City.",
              fourth:
                "Plötzlich erscheint eine Warnmeldung auf Deinem Bildschirm: Onshore-Winde sorgen für Aufruhr und Chaos am Strand.",
            },
            step1:
              "Eine riesige Quallenpopulation wird durch Off-Shore-Winde in die Bucht von Sunset-City gedrückt.",
            step2: {
              first:
                "Die Strände sind in Gefahr, erste Touristen haben ihre Buchungen storniert. Du musst handeln, Bürgermeister.",
              second:
                "Zum Glück hast Du vor zwei Jahren in ein neues maritimes Forschungsinstitut investiert. Du berufst einen Call mit der Chefwissenschaftlerin ein.",
              third:
                "Das Forscherteam setzt Segel auf der MS Science Rocks um die Lage zu sichten.",
            },
            step3: {
              first:
                'Chefwissenschaftlerin: "Das sieht mir sehr nach einer Quallenblüte aus."',
              second:
                'Bürgermeister: "Was auch immer das am Strand ist - Sie müssen mir helfen es loszuwerden."',
              third:
                'Chefwissenschaftlerin: "Geben Sie uns zwei Wochen, Bürgermeister. Bis dahin können wir Sie mit ersten Resultaten versorgen.',
            },
            step4:
              "Zwei Wochen?! Für eine Wissenschaftlerin mag das schnell sein, aber wenn Du nicht sofort handelst, nimmt Dich die Boulevardpresse auseinander.",
            step5: {
              intro:
                "Das Einzige, was Dir in dieser schwierigen Lage einfällt, ist Deine Fischereiflotte vor der Küste.",
              input: "Was willst Du machen, um die Quallenplage zu stoppen?",
              correct:
                "Gute Neuigkeiten, wir haben uns den Lebenszyklus der Quallen angeschaut. Und ich muss schon sagen: Die Bürger von Sunset-City haben Sie nicht ohne Grund zum Bürgermeister gemacht: Ihre Entscheidung, weniger Fischerei zuzulassen, war genau die Richtige. Viele Fische ernähren sich von den Quallen und sind damit ihre natürlichen Fressfeinde.",
              wrong: {
                explanation:
                  "Gute Neuigkeiten, wir haben uns den Lebenszyklus der Quallen angeschaut. Die schlechte Nachricht: Ihre Entscheidung, mehr Fischerei zuzulassen, war die Falsche. Viele Fische ernähren sich von den Quallen. Und genau die haben Sie jetzt rausgefischt.",
              },
              autopilot:
                "Um die Quallen längerfristig zu dezimieren, brauchen wir mehr Raubtiere im mehr, also mehr Fische und entsprechend weniger Fischfang.",
              explanation: {
                headline: "Lebenszyklus Qualle",
                list1: "Quallenmedusa gibt Eier und Spermien ab.",
                list2: "Quallenlarve schwimmt im Meerwasser.",
                list3:
                  "Larve setzt sich auf hartem Untergrund ab und wird zum Polyp.",
                list4:
                  "Polypen wachsen, verlängern sich und bringen viele junge Medusen hervor.",
                list5:
                  "Junge Quallen reifen heran und der Zyklus beginnt von neuem.",
              },
            },
            step6: {
              first:
                "Mit Hilfe der Wissenschaftler hast Du verhindert, dass die Küste von Sunset City von wabbeligen Quallen heimgesucht wird.",
              second:
                "Die Strände sind wieder nutzbar und die Zahl der Touristen steigt.",
              third:
                "Die Bürger sind beruhigt. Es ist schön in Deiner Stadt, ein neues Wohngebiet entsteht.",
            },
          },
          sceneB: {
            step1:
              "Die Lage scheint sich beruhigt zu haben, doch Du erhältst beunruhigende Nachrichten aus Deinem Versorgungsministerium.",
            step2: {
              first:
                "Die ersten Supermärkte haben nicht mehr genügend Gemüse im Regal.",
              second:
                "Beim Fisch sieht es nicht besser aus. Die Fischer beklagen sich:",
              third:
                '"Ihr wollt Fisch? Dann stoppt die Politiker mit ihren lächerlichen Fischfangquoten!"',
              fourth:
                '"Quallen loszuwerden ist nicht so einfach: Zur Zeit steigt auch noch die Temperatur des Meeres. Die Quallen lieben das und vermehren sich explosionsartig."',
              fifth:
                "Hamsterkäufe führen zu Konflikten unter Sunset Citys Einwohnern.",
            },
            step3: {
              first: "Du fragst in Deinem Forschungsinstitut um Rat.",
              second:
                "Die Wissenschaftler sind auf der Suche nach einer Alternative für die derzeit begrenzten Fangquoten.",
              third:
                "Gleichzeitig suchen sie nach Methoden, um die Quallenpopulation zu kontrollieren.",
              fourth: "Es muss doch einen Weg geben, sie sinnvoll zu nutzen?",
              fifth: 'Bürgermeister: "Und das funktioniert?!"',
              sixth:
                'Chefwissenschaftlerin: "Wir konnten mit Hilfe eines italienischen Gourmetkochs ein Substrat aus dem wässrigen Quallenfleisch extrahieren."',
              seventh: '"Kosten Sie mal diesen Burger..."',
            },
            step4:
              "Du bist hellauf begeistert. Und errichtest Deine erste Quallenburgerfabrik.",
            step5: {
              intro: {
                first:
                  "Die Produktion von Quallenburgern hat in jeder Hinsicht Vorteile.",
                second:
                  "Positive Energie fließt durch Deinen Körper als Du bei der Baustelle ankommst.",
              },
              input:
                "Was willst Du tun, um die Versorgung mit Nahrungsmitteln zu sichern?",
              correct: {
                correct:
                  '"Bravo, Bürgermeister! Es ist Ihnen gelungen, ein ausgewogenes Verhältnis herzustellen. Den Einfluss der Fischerei auf das Quallenwachstum kannten Sie ja bereits. Durch den Bau der Burgerfabriken haben Sie nun Abnehmer für die Tiere. Das haben Sie gut in Ihre Überlegungen miteinbezogen."',
                quiteCorrect:
                  '"Bürgermeister, ganz erstaunlich, wie gut Sie über die verschiedenen Faktoren informiert sind. Es gibt noch Raum für Verbesserungen, aber das ist eine ganz andere Sache."',
              },
              wrong: {
                totallyWrongTooMany:
                  "Bürgermeister, leider ist es Ihnen nicht gelungen, ein ausgewogenes Verhältnis herzustellen. Den Einfluss der Fischerei auf das Quallenwachstum kennen Sie ja bereits. Durch den Bau der Burgerfabriken haben Sie nun allerdings Abnehmer für die Tere. Das hätten Sie in Ihre Überlegungen besser mit einbeziehen müssen.",
                wrongTooMany:
                  "Bürgermeister, leider haben Sie es nicht geschafft, das richtige Gleichgewicht zu finden. Es gibt jetzt viel zu viele Quallen; Sie haben Ihre Ressourcen nicht weise eingesetzt.",
                totallyWrongTooFew:
                  "Bürgermeister, leider ist es Ihnen nicht gelungen, ein ausgewogenes Verhältnis herzustellen. Den Einfluss der Fischerei auf das Quallenwachstum kennen Sie ja bereits. Durch den Bau der Burgerfabriken haben Sie nun allerdings Abnehmer für die Tere. Das hätten Sie in Ihre Überlegungen besser mit einbeziehen müssen.",
                wrongTooFew:
                  "Bürgermeister, leider konnten Sie kein Gleichgewicht herstellen. Ihre Produktion erfordert mehr Quallen, als Ihnen zur Verfügung stehen. Versuchen Sie beim nächsten Mal, klüger zu wählen.",
              },
              autopilot:
                " Wir haben Ihnen das optimale Verhältnis von Landwirtschaftsbetrieben, Fischerei und Burgerfabriken berechnet. Wir würden Ihnen raten, unsere Empfehlungen in den kommenden Monaten umsetzen.",
              explanation: {
                headline: "Quallen als Nahrungsmittel",
                headline1: "Beliebte essbare Quallen:",
                list1:
                  "1. australische Spotter-Qualle 2. Ohrenqualle 3. Flammenqualle",
                headline2: "Quallen-Produzenten:",
                list2:
                  "China, Indonesien, Korea, Malaysia, Burma, Philippinen, Thailand",
                headline3: "Quallen-Abnehmer:",
                list3:
                  "China, Korea, Taiwan, Vietnam, Japan, Europa [Einstieg], USA, Neuseeland, Australien",
              },
            },
            step6: {
              first: "Deine Stadt entwickelt sich prächtig.",
              second:
                "Die Burger aus der Quallen-Burgerfabrik verkaufen sich wie geschnitten Brot.",
              third: "Bei der Fischerei hast Du ein gutes Maß gefunden.",
              fourth:
                "Zufrieden blickst Du auf die vielen Felder, die nun in der Flussmündung von den Landwirten bewirtschaftet werden.",
            },
          },
          sceneC: {
            step1:
              "Doch was ist das? Die Farbe des Wasser gefällt Dir gar nicht. Es scheint so, als würde mit dem Fluss etwas ins Meer gespült. Du bittest Deine Wissenschaftler um Rat.",
            step2: {
              first:
                "Die Wissenschaftler legen mit der MS Sciencerocks ab und fahren in die Flussmündung.",
              second:
                "Schnell nehmen sie nehmen Wasserproben und untersuchen sie noch vor Ort.",
            },
            step3: {
              first:
                'Chefwissenschaftlerin: "Bürgermeister, es steht außer Frage: Das Meer ist in Gefahr. Auf den Feldern wird zu viel Dünger in Form von Gülle ausgebracht."',
              second:
                '"Der Boden ist übersättigt, er kann die Nährstoffe nicht mehr aufnehmen. Stickstoff- und Phosphorverbindungen landen im Meer."',
              third:
                '"Für die meisten Meeresbewohner ist das ein Problem, nur die Quallen fühlen sich wohl."',
              fourth: 'Bürgermeister: "Was machen wir nun?"',
              fifth:
                '"Wir könnten biologischen Dünger aus den Quallen herstellen. Wir sind schon relativ weit fortgeschritten bei der Erforschung dieser Nutzungsmöglichkeit."',
              sixth:
                '"Bürgermeister, wir sollten es wagen und eine Düngemittelfabrik errichten."',
            },
            step4:
              "Du bist einverstanden und errichtest Deine erste Quallendüngemittel-Fabrik.",
            step5: {
              intro: {
                first:
                  "Mit dem Quallendünger hast Du noch eine weitere Option, um für Deine Stadt das perfekte Gleichgewicht zu finden.",
                second:
                  "Du kannst Deine Bürger und Gäste mit ausreichend Gemüse und Obst versorgen und gleichzeitig die Quallen in der Bucht in Schach halten.",
                third:
                  "Wenn Du der Landwirtschaft Quallendünger zu Verfügung stellst, werden Erträge stabil bleiben, aber weniger Nährstoffe ins Meer gespült.",
              },
              inputs:
                "Was willst Du tun, um die Überdüngung Deiner Küste zu verhindern?",
              correct: {
                correct:
                  "Großartig. Sie haben ein optimales Verhältnis von Landwirtschaftsbetrieben, Fischerei und Burgerfabriken erreicht. Und mit Hilfe unseres neuen Jelly Grow-Düngers konnten Sie die Überdüngung verhindern und trotzdem die Erträge steigern.",
                quiteCorrect:
                  "Bürgermeister, ziemlich erstaunlich, wie gut Sie über die verschiedenen Faktoren informiert sind. Es gibt noch Raum für Verbesserungen, aber das ist eine ganz andere Sache.",
              },
              wrong: {
                totallyWrongTooMany:
                  '"Bürgermeister, leider ist es Ihnen nicht gelungen, ein ausgewogenes Verhältnis herzustellen. Die Fischpopulationen leidet unter Überdüngung, die Quallen können sich hingegen rasant vermehren. Somit haben ihre Fischer keine Erträge und der Tourismus ist gefährdet."',
                wrongTooMany:
                  '"Bürgermeister, leider haben Sie es nicht geschafft, das richtige Gleichgewicht zu finden. Es gibt jetzt viel zu viele Quallen; Sie haben Ihre Ressourcen nicht weise eingesetzt."',
                totallyWrongTooFew:
                  '"Der neue Quallendünger ist wirklich außergewöhnlich. Es werden kaum Nährstoffe ins Meer gespült. Das ist gut für die Meeresbewohner. Für fast alle: Die Quallenpopulation ist rapide geschrumpft. Woraus sollen Burger hergestellt werden? Das ist kein ausgewogenes Verhältnis, Bürgermeister."',
                wrongTooFew:
                  '"Bürgermeister, leider konnten Sie kein Gleichgewicht schaffen. Ihre Produktion erfordert mehr Quallen, als Ihnen zur Verfügung stehen. Versuchen Sie beim nächsten Mal, klüger zu wählen."',
              },
              autopilot:
                "Wir müssen aus unseren Fehlern lernen - und schnell handeln. Nur nicht den Kopf verlieren. Unsere Analyse der Situation in der Bucht und die Forschungsergebnisse von Kollegen aus Japan lassen konkrete Schlüsse zu. Setzen Sie diese Empfehlungen direkt um.",
              explanation: {
                headline: "ÜBERDÜNGUNG",
                first: "Düngemittel wird auf den Boden aufgebracht.",
                second:
                  "Nährstoffe gelangen in den Boden und fließen in das Oberflächenwasser ab.",
                third:
                  "Nährstoffe werden über das Land ins Meerwasser gespült.",
                fourth: "Überschüssige Nährstoffe verursachen Algenblüte.",
                fifth:
                  "Die Algenblüte verringert die Sonneneinstrahlung ins Wasser.",
                sixth:
                  "Pflanzen und Organismen unter der Algenblüte sterben ab, weil sie keine Photosynthese betreiben können.",
                seventh:
                  "Die Algenblüte stirbt ab und sinkt auf den Grund, Bakterien beginnen mit der Zersetzung des organischen Materials unter Verwendung von Sauerstoff.",
                eighth:
                  "Durch die Zersetzungsprozesse wird Sauerstoff verbraucht, der für größere Lebewesen, wie z. B. Fische, unerlässlich ist.",
              },
            },
            step6: {
              first:
                "Die Gefahr, Deine wunderschöne Küste zu verlieren, ist gebannt.",
              second: "Die Nährstoffkonzentrationen halten sich im Rahmen.",
              third:
                "Dank des neuen Düngers aus Quallen sind die Erträge der Landwirtschaft gut.",
            },
          },
          sceneD: {
            step1:
              "Es gibt neuen Ärger:  Das Düngewerk und die Burgerfabrik benötigen mehr Quallen, um den gesteigerten Bedarf abdecken zu können.  Hoffentlich haben die Wissenschaftler eine Lösung parat.",
            step2: {
              first: "Du machst Dich auf den Weg in die Forschungsstation.",
              second:
                "Es wäre nicht das erste Mal, dass Deine Chefwissenschaftlerin Dir aus der Patsche hilft.",
              third:
                'Chefwissenschaftlerin: "Sie kommen gerade rechtzeitig. Folgen Sie mir."',
              fourth:
                '"Glückwunsch, Bürgermeister. Sie haben inzwischen einen ganzen Industriezweig für die Quallen geschaffen und nutzen die Tiere auf unterschiedlichste Weise."',
              fifth:
                '"Aber wie wir gerade erst erleben mussten, ist der Erfolg stark abhängig von der Quallenpopulation im Meer."',
            },
            step3: {
              first:
                'Chefwissenschaftlerin: "Ich zeige Ihnen die neue Errungenschaft. Es wird Ihnen helfen, die Quallennachfrage besser zu kontrollieren."',
              second:
                '"Wir haben eine Weg gefunden, Quallen an Land zu züchten."',
              third:
                '"Mit einer Aquakultur sind wir unabhängig von den Launen der Natur. Denken Sie nur an die Auswirkungen, die ein Rückgang der Wassertemperatur auf das Quallenvorkommen hätte."',
              fourth:
                '"Sie sollten wenigstens einen Teil Ihrer Quallenproduktion an Land verlegen."',
            },
            step4:
              "Der Quallenmangel darf kein Dauerzustand bleiben. Du vertraust Deinen Wissenschaftlern und baust eine Aquakultur für die Quallenzucht an Land auf.",
            step5: {
              intro: {
                first:
                  'Chefwissenschaftlerin: "Das mag für Sie verrückt klingen. Erst versuchen wir alles, um die Quallen loszuwerden und nun bauen wir teure Zuchtanlagen an Land."',
                second:
                  '"Aber bedenken Sie, Bürgermeister, das macht Sie unabhängig von den Launen des Meeres. Sie müssen ein Gleichgewicht zwischen der Quallenverbrauch und Produktion herstellen."',
                third:
                  '"Wenn Sie der Landwirtschaft Quallendünger zur Verfügung stellen, bleiben die Erträge stabil, aber es werden weniger Nährstoffe ins Meer gespült."',
              },
              inputs:
                "WAS WERDEN SIE TUN, UM DEN QUALLENMANGEL ZU BEENDEN, OHNE EINE QUALLENPLAGE ZU RISKIEREN?",
              correct: {
                correct:
                  '"Bürgermeister, Sie überraschen mich immer wieder. Diese vielen verschiedenen Faktoren unter einen Hut zu bringen... das ist eine fast unmenschliche Aufgabe. Und Sie haben es geschafft. Sie haben sich einen Urlaub verdient."',
                quiteCorrect:
                  "Bürgermeister, ganz erstaunlich, wie gut Sie über die verschiedenen Faktoren informiert sind. Es gibt noch Luft nach oben. Aber Sie haben sich einen Urlaub verdient.",
              },
              wrong: {
                totallyWrongTooMany:
                  'Chefwissenschaftlerin: "Bürgermeister, leider ist es Ihnen nicht gelungen, ein ausgewogenes Verhältnis herzustellen. Ihre Aquakultur läuft auf Hochtouren, aber sie haben nicht bedacht, dass auch noch Quallen im Meer gefischt werden müssen. Nun sind die Preise im Keller und die Fischer konzentrieren sich lieber auf andere Tiere. Im Meer gibt es zu viele Quallen, am Strand liegen die ersten Exemplare."',
                wrongTooMany:
                  '"Bürgermeister, leider haben Sie es nicht geschafft, das richtige Gleichgewicht zu finden. Es gibt jetzt viel zu viele Quallen; Sie haben Ihre Ressourcen nicht weise eingesetzt."',
                totallyWrongTooFew:
                  '"Bürgermeister, leider ist es Ihnen nicht gelungen, ein ausgewogenes Verhältnis herzustellen.  Die Landwirte sind begeistert vom Quallendünger, aber es sind nicht ausreichend Quallen gefischt worden, um ihn zu produzieren. Die Erträge der Aquakultur reichen nicht aus."',
                wrongTooFew:
                  '"Bürgermeister, leider konnten Sie keine Bilanz ziehen. Ihre Produktion erfordert mehr Quallen, als Ihnen zur Verfügung stehen. Versuchen Sie beim nächsten Mal, klüger zu wählen."',
              },
              autopilot:
                '"Bürgermeister, dank Ihrer jüngsten Investition konnten wir den Hochleistungscomputer Q-Rvastic kaufen. Er hat das perfekte Verhältnis aller Faktoren berechnet. Wenn Sie das umgesetzt haben, sollten Sie wirklich Urlaub machen. Sie sehen müde aus."',
              explanation: {
                text: "Ein Quallenaquarium versucht, die Meeresströmung zu imitieren, ohne die sich Quallen nicht bewegen können. Es hat keine Ecken und Kanten, an denen sich die Quallen verletzen könnten. Das Aquarium hat eine runde Form, die eine kreisförmige Strömung erzeugt. Auf diese Weise schwimmen die Quallen immer sanft im Kreis, ohne am Boden zu kleben. Ein Quallenaquarium besteht aus einem Wasserein- und -auslass, einer Pumpe, einer Filtereinheit und einer LED-Beleuchtung.",
                headline: "QUALLENAQUARIUM",
              },
            },
          },
          sceneE: {
            step1: {
              first:
                "Du hast Dir den Rat Deiner Wissenschaftlerin zu Herzen genommen: Und machst eine Woche Urlaub an der schönsten Küste weit und breit.",
              second:
                "An den wunderschönen Stränden von Sunset City natürlich.",
              third: "Aber irgendwie kannst Du nicht abschalten.",
              fourth: "Und brichst Deinen Urlaub ab.",
              fifth:
                "Zurück am Schreibtisch willst Du Dich gerade auf den neuesten Bericht aus Deinem Lieblingsforschungsinstitut stürzen, der in der Zwischenzeit eingetrudelt ist.",
              sixth:
                "Da überschlagen sich die Ereignisse. Suncitys Alarmsysteme springen an.",
              seventh:
                "Der boomender Tourismus hat Spuren hinterlassen: Das Meer ist voller Mikropastik. Sogar im Trinkwasser wurden erste Partikel entdeckt.",
              eigth:
                "Eine Katastrophe - denn Sunset City ist Trinkwasserreservoir fürs ganze Land. Menschen und Tiere nehmen gesundheitsschädliches Mikroplastik auf.",
            },
            step2: {
              first:
                "Mikroplastik entsteht, wenn größere Plastikteile im Meer durch Wind- und Wellenbewegung in immer kleinere Teile zersetzt werden.",
              second:
                "Es entsteht auch durch Kleidungsabrieb beim Wäschewaschen und ist Bestandteil vieler Kosmetikprodukte, wie Cremes und Peelings.",
              third:
                "Da Mikro-oder Nanoplastik zu klein ist, um von den Klärwerken gefiltert zu werden, gelangen die Teilchen ins Wasser. So landet Mikroplastik im Meer und in unserem Abwasser.",
            },
            step3: {
              first: 'Bürgermeister: "Herr Präsident, was tun Sie denn hier?"',
              second:
                'Präsident: "Bürgermeister, sind Sie von allen guten Geistern verlassen?! Steigende Bevölkerungszahlen und boomender Tourismus in Ihrer Kleinstadt, überall Autos, tonnenweise Müll im Meer und nun auch noch Mikroplastik im Trinkwasser..."',
              third:
                '"Unser gesamtes Land hängt von den Wasserspeichern in Sunset-City ab. Ich dulde keinen einzigen Nanopartikel Plastik in unserem Trinkwasser. Was Sie da anstellen, geht auf Kosten des Meeres! Und auf Kosten der Bevölkerung unseres schönen Landes..."',
              fourth:
                '"Ich stelle Ihnen ein Ultimatum, Bürgermeister. Legen Sie mir eine Lösung vor, sonst sind Sie lange genug Bürgermeister von Sunset City gewesen."',
            },
            step4: {
              first: "Was ist das? Oh, der Forschungsbericht.",
              second:
                "Um Dich von dem Desaster abzulenken, beginnst Du den Brief Deiner Chefwissenschaftlerin zu lesen.",
              third:
                '"Sehr geehrter Herr Bürgermeister, Sie haben immer an uns geglaubt und uns unterstützt..."',
              fourth:
                '"deswegen freue ich mich Ihnen im Namen des ganzen Forschungsteams als einem der ersten von der absolut einmaligen sensationellen Entdeckung berichten zu können."',
              fifth:
                '"Lassen Sie es mich so erklären: Quallen sondern Schleim ab. Er hat eine Schutzfunktion und wehrt Feinde, Fremdkörper und Mikroorganismen ab."',
              sixth:
                '"Und das ist der springende Punkt: Quallenschleim hat faszinierende Eigenschaften: Das darin enthaltene Hydrogel ist in der Lage, Mikropartikel zu binden, selbst kleinste Nanopartikel. ..."',
              seventh:
                '"Schauen Sie sich unsere Zeichnung eines innovativen Filtersystems an: Wenn sich die Partikel mit dem Hydrogel verbinden, bilden sich Klumpen. Diese können dann leicht aus dem Wasser herausgefiltert werden."',
              eigth:
                '"Die Kläranlagen von Sunset City könnten leicht mit dieser Technologie aufgerüstet werden und wären dann viel effektiver. Wir nennen die Erfindung Jelly Pure".',
              nineth: "Heureka, das ist die Rettung!",
              tenth:
                "Wir können damit Mikroplastik aus unserem Trinkwasser filtern! Diese Wissenschaftler sind einfach genial!",
            },
            step5: {
              first:
                "Du springst in Dein Auto und rast zum Forschungsinstitut.",
              second:
                'Bürgermeister: "Sie haben mich gerettet - und Sunset City!"',
            },
            end: {
              headlineGood: "Du hast es geschafft!",
              headlineBad: "Du hast es geschafft!*",
              subline: "* aber du kannst es besser machen!",
              informationGood:
                "Herzlichen Glückwunsch, Sie wissen wie man Sunset-City rettet.",
              informationBad:
                "Nun, ohne Ihre Wissenschaftler wären Sie ziemlich aufgeschmissen gewesen.",
              points: "Sie haben {{points}} von 2400 Punkten erreicht.",
            },
          },
          // here we will place our translations...
        },
      },
    },
  });

export default i18n;
