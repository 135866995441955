import { MapStep } from "../../../prefabs/MapStep";
import { MarkerDefinition, StepComponent } from "types";
import GameManager from "GameManager";
import TextTypewriter from "components/ui/TextTypewriter";
// assets
import mapImage from "assets/scenes/C_01/C_01_01_map.jpg";
import { useTranslation } from "react-i18next";

type Step1Props = {};

export const marker: Array<MarkerDefinition> = [
  {
    position: { x: 1518, y: 468 },
    onClick: () => {
      GameManager.next();
    },
    padding: {
      bottom: 204,
    },
  },
];

export const mapBackground: string = mapImage;

const Step1: StepComponent<Step1Props> = () => {
  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      timesheet={[
        {
          offset: 1,
          text: t("sceneC.step1"),
        },
      ]}
    />
  );
  return <MapStep typewriter={typewriter} />;
};

const exports = { component: Step1, marker, mapBackground };

export default exports;
