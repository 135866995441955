import styles from "./OtherSides.module.scss";

import jelly from "assets/images/gojelly-white.png";
import eu from "assets/images/eu.png";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={styles.inner}>
          <a
            href="https://gojelly.eu"
            target="_blank"
            className={styles.jelly}
            rel="noreferrer"
          >
            <img className={styles.jelly} src={jelly} alt=""></img>
          </a>
          <div className={styles.goJelly}>
            <h4>GoJelly</h4>
            <p>
              Within GoJelly we will develop, test and promote a gelatinous
              solution to microplastic pollution by developing a TRL 5-6
              prototype microplastics filter made of jellyfish mucus.
            </p>
          </div>
          <div className={styles.getInTouch}>
            <h4>Get in touch today</h4>
            <a href="mailto:gojelly-info@geomar.de">gojelly-info@geomar.de</a>
          </div>
          <div className={styles.eu}>
            <h4>Get in touch</h4>
            <div className={styles.euContent}>
              <img src={eu} alt="" />
              <div>
                This project has received funding from the European Union’s
                Horizon 2020 research and innovation programme under grant
                agreement No 774499”
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <span className={styles.copyright}>(c) 2018 | Gojelly.eu</span>
      </div>
    </div>
  );
};
