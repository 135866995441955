import SceneA from "./Scene";
import steps from "./steps";

const IDENTIFIER = "SCENEA";

const component = SceneA;

const Scene = {
  IDENTIFIER,
  steps,
  component,
};

export default Scene;
