import {
  addPoints,
  isLessAgriculture,
  isLessAquaCulture,
  isLessBurgerFactories,
  isLessFertilizer,
  isLessFish,
  isMoreAgriculture,
  isMoreAquaCulture,
  isMoreBurgerFactories,
  isMoreFertilizer,
  isMoreFish,
  SolutionState,
  Step5Context,
} from "helpers/stateMachineTransistionHelpers";
import { AnyEventObject, createMachine, send } from "xstate";

const registerResults = (context: Step5Context, event: AnyEventObject) => {
  context.decisionFish = event.decision.fishing;
  context.decisionAgriculture = event.decision.agriculture;
  context.decisionBurgerFactories = event.decision.burgerfactories;
  context.decisionFertilizer = event.decision.fertilizer;
  context.decisionAquaCulture = event.decision.aquaculture;
};

export const StateMachine = createMachine<Step5Context>({
  id: "step5",
  initial: "Intro",
  states: {
    Intro: {
      on: {
        REPEAT: "Intro",
        CHOOSEN_DECISIONS: {
          actions: [registerResults, send("START_DECISION_TREE")],
        },
        START_DECISION_TREE: [
          {
            cond: isLessFish,
            actions: [send("LF")],
          },
          {
            cond: isMoreFish,
            actions: [send("MF")],
          },
        ],
        LF: [
          {
            cond: isLessAgriculture,
            actions: [send("LF_LA")],
          },
          {
            cond: isMoreAgriculture,
            actions: [send("LF_MA")],
          },
        ],
        MF: [
          {
            cond: isLessAgriculture,
            actions: [send("MF_LA")],
          },
          {
            cond: isMoreAgriculture,
            actions: [send("MF_MA")],
          },
        ],
        LF_LA: [
          {
            cond: isLessBurgerFactories,
            actions: [send("LF_LA_LBF")],
          },
          {
            cond: isMoreBurgerFactories,
            actions: [send("LF_LA_MBF")],
          },
        ],
        LF_MA: [
          {
            cond: isLessBurgerFactories,
            actions: [send("LF_MA_LBF")],
          },
          {
            cond: isMoreBurgerFactories,
            actions: [send("LF_MA_MBF")],
          },
        ],
        MF_LA: [
          {
            cond: isLessBurgerFactories,
            actions: [send("MF_LA_LBF")],
          },
          {
            cond: isMoreBurgerFactories,
            actions: [send("MF_LA_MBF")],
          },
        ],
        MF_MA: [
          {
            cond: isLessBurgerFactories,
            actions: [send("MF_MA_LBF")],
          },
          {
            cond: isMoreBurgerFactories,
            actions: [send("MF_MA_MBF")],
          },
        ],
        LF_LA_LBF: [
          {
            cond: isLessFertilizer,
            actions: [send("LF_LA_LBF_LFZ")],
          },
          {
            cond: isMoreFertilizer,
            actions: [send("LF_LA_LBF_MFZ")],
          },
        ],
        LF_LA_MBF: [
          {
            cond: isLessFertilizer,
            actions: [send("LF_LA_MBF_LFZ")],
          },
          {
            cond: isMoreFertilizer,
            actions: [send("LF_LA_MBF_MFZ")],
          },
        ],
        LF_MA_LBF: [
          {
            cond: isLessFertilizer,
            actions: [send("LF_MA_LBF_LFZ")],
          },
          {
            cond: isMoreFertilizer,
            actions: [send("LF_MA_LBF_MFZ")],
          },
        ],
        LF_MA_MBF: [
          {
            cond: isLessFertilizer,
            actions: [send("LF_MA_MBF_LFZ")],
          },
          {
            cond: isMoreFertilizer,
            actions: [send("LF_MA_MBF_MFZ")],
          },
        ],
        MF_LA_LBF: [
          {
            cond: isLessFertilizer,
            actions: [send("MF_LA_LBF_LFZ")],
          },
          {
            cond: isMoreFertilizer,
            actions: [send("MF_LA_LBF_MFZ")],
          },
        ],
        MF_MA_LBF: [
          {
            cond: isLessFertilizer,
            actions: [send("MF_MA_LBF_LFZ")],
          },
          {
            cond: isMoreFertilizer,
            actions: [send("MF_MA_LBF_MFZ")],
          },
        ],
        MF_MA_MBF: [
          {
            cond: isLessFertilizer,
            actions: [send("MF_MA_MBF_LFZ")],
          },
          {
            cond: isMoreFertilizer,
            actions: [send("MF_MA_MBF_MFZ")],
          },
        ],
        LF_LA_LBF_LFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("LF_LA_LBF_LFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("LF_LA_LBF_LFZ_MAQ")],
          },
        ],
        LF_LA_LBF_MFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("LF_LA_LBF_MFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("LF_LA_LBF_MFZ_MAQ")],
          },
        ],
        LF_LA_MBF_LFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("LF_LA_MBF_LFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("LF_LA_MBF_LFZ_MAQ")],
          },
        ],
        LF_MA_LBF_LFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("LF_MA_LBF_LFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("LF_MA_LBF_LFZ_MAQ")],
          },
        ],
        LF_MA_LBF_MFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("LF_MA_LBF_MFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("LF_MA_LBF_MFZ_MAQ")],
          },
        ],
        LF_MA_MBF_LFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("LF_MA_MBF_LFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("LF_MA_MBF_LFZ_MAQ")],
          },
        ],
        LF_MA_MBF_MFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("LF_MA_MBF_MFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("LF_MA_MBF_MFZ_MAQ")],
          },
        ],
        MF_LA_LBF_LFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("MF_LA_LBF_LFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("MF_LA_LBF_LFZ_MAQ")],
          },
        ],
        MF_LA_LBF_MFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("MF_LA_LBF_MFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("MF_LA_LBF_MFZ_MAQ")],
          },
        ],
        MF_MA_LBF_MFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("MF_MA_LBF_MFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("MF_MA_LBF_MFZ_MAQ")],
          },
        ],
        MF_MA_MBF_LFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("MF_MA_MBF_LFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("MF_MA_MBF_LFZ_MAQ")],
          },
        ],
        MF_MA_MBF_MFZ: [
          {
            cond: isLessAquaCulture,
            actions: [send("MF_MA_MBF_MFZ_LAQ")],
          },
          {
            cond: isMoreAquaCulture,
            actions: [send("MF_MA_MBF_MFZ_MAQ")],
          },
        ],
        MF_MA_MBF_MFZ_MAQ: {
          target: "Correct",
          actions: addPoints(600, SolutionState.Correct),
        }, // A1
        MF_MA_MBF_MFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooFew),
        }, // A2
        MF_MA_MBF_LFZ_MAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooMany),
        }, // A3
        MF_MA_MBF_LFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooFew),
        }, // A4
        MF_MA_LBF_MFZ_MAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooMany),
        }, // A5
        MF_MA_LBF_MFZ_LAQ: {
          target: "Correct",
          actions: addPoints(450, SolutionState.QuiteCorrect),
        }, // A6
        MF_MA_LBF_LFZ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooMany),
        }, // A7 + A8
        MF_LA_LBF_MFZ_MAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooMany),
        }, // B1
        MF_LA_LBF_MFZ_LAQ: {
          target: "Correct",
          actions: addPoints(450, SolutionState.QuiteCorrect),
        }, // B2
        MF_LA_LBF_LFZ_MAQ: {
          target: "Wrong",
          actions: addPoints(300, SolutionState.TooMany),
        }, // B3
        MF_LA_LBF_LFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooMany),
        }, // B4
        MF_LA_MBF: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooMany),
        }, // B5 - B8
        LF_MA_MBF_MFZ_MAQ: {
          target: "Correct",
          actions: addPoints(600, SolutionState.Correct),
        }, // C1
        LF_MA_MBF_MFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooFew),
        }, // C2
        LF_MA_MBF_LFZ_MAQ: {
          target: "Correct",
          actions: addPoints(450, SolutionState.QuiteCorrect),
        }, // C3
        LF_MA_MBF_LFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooFew),
        }, // C4
        LF_MA_LBF_MFZ_MAQ: {
          target: "Correct",
          actions: addPoints(450, SolutionState.QuiteCorrect),
        }, // C5
        LF_MA_LBF_MFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooFew),
        }, // C6
        LF_MA_LBF_LFZ_MAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooMany),
        }, // C7
        LF_MA_LBF_LFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooFew),
        }, // C8
        LF_LA_MBF_MFZ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooFew),
        }, // D1 + D2
        LF_LA_MBF_LFZ_MAQ: {
          target: "Correct",
          actions: addPoints(450, SolutionState.QuiteCorrect),
        }, // D3
        LF_LA_MBF_LFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooFew),
        }, // D4
        LF_LA_LBF_MFZ_MAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooMany),
        }, // D5
        LF_LA_LBF_MFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooFew),
        }, // D6
        LF_LA_LBF_LFZ_MAQ: {
          target: "Wrong",
          actions: addPoints(400, SolutionState.TooMany),
        }, // D7
        LF_LA_LBF_LFZ_LAQ: {
          target: "Wrong",
          actions: addPoints(300, SolutionState.TooFew),
        }, // D8
      },
    },
    Wrong: {
      on: {
        CONTINUE: "Autopilot",
      },
    },
    Autopilot: {
      on: {
        CONTINUE: "Explanation",
      },
    },
    Correct: {
      on: {
        CONTINUE: "Explanation",
      },
    },
    Explanation: {
      type: "final",
    },
  },
});
