import { OtherSide } from "./OtherSide";
// Assets
import aboutHeader from "assets/images/gojelly_site_bg_03.jpg";

import styles from "./OtherSides.module.scss";

export const About = () => {
  const content = (
    <div className={styles.contentText}>
      <p>
        <strong>{`FROM SCIENCE TO PUBLIC`}</strong>
      </p>
      <p>
        {`This socio-ecological strategy game was developed within the HORIZON  
        2020 project GoJelly, financed by the European Union.`}
      </p>
      <p>
        {`The underlying idea was to create a strategy game for the public as well 
        as for educational purposes, giving an overview of the different research  
        areas within the project and to show the tradeoffs between social, economic 
        and ecologic management decisions in a world of jellyfish. The project brought 
        together expertise from science, programming, storytelling and design to 
        fulfill this mission.`}
      </p>
      <p>
        <strong>{`AIM OF THE GAME`}</strong>
      </p>
      <p>
        {`This strategy game demonstrates the complexity of a marine system with 
        jellyfish in it, under changing environmental conditions, giving solution 
        approaches for a sustainable management to the interested public. It is focused 
        on the human elements of the socio-ecological system as both impacted by and in 
        turn causing changes in the marine environment. Plastics in the oceans is but one 
        of the problems to solve in the GoJelly game.  Others are eutrophication, 
        sustainable food production and jellyfish aquaculture to manage jellyfish as a 
        plague or a resource within trade-off analysis of different eco-system goods and 
        services.`}
      </p>
      <p>
        <strong>{`GAME INSTRUCTIONS`}</strong>
      </p>
      <p>
        {`The player slips into the role of the mayor of Sunset City. The mayor luckily 
        has support in the form of a capable research team. In each mission the player must 
        choose wisely in favor of his beautiful city, always having an eye on the Sea in 
        front of it. The player has several options to interact with jellyfish. Environmental 
        factors and good or bad decisions influence the course of the game and the score. 
        The final goal is to reach a sustainably balanced system with jellyfish.`}
      </p>
      <p>
        {`The game is browser-based and single player. It takes about 15 minutes to play and can
        be used in different educational settings to dive deeper into specific topics of the 
        project or serve as a first overview to sustainable marine ecosystem management and its 
        different aspects.`}
      </p>
      <p>
        {`GoJelly is available in English. For the best gaming experience, it is recommended to 
        play in the Chrome browser on the desktop PC or a tablet. This game loads large videos and 
        should be played on Wi-Fi to save your data volume.`}
      </p>
      <p>
        <strong>{`BACKGROUND`}</strong>
      </p>
      <p>
        {`The fact that jellyfish numbers are increasing in the ocean, as in the game, is not an
        imagined scenario. It is an already visible consequence of global warming. That is why the
        EU-funded GoJelly project has set itself the task of seeing jellyfish not as a problem, but
        as part of the solution. An international team of researchers is working on methods to make
        the jellyfish usable. Players of the strategy game learn the ways in which this can be done.
        One of the goals of the GoJelly project is to make people aware of the many ways in which
        jellyfish can be used and to reduce their fear of contact with them.`}
      </p>
      <p>{`You find more information about the research project here:`}</p>
      <p>
        <a href="https://gojelly.eu/">https://gojelly.eu/</a>
      </p>
    </div>
  );

  return (
    <OtherSide
      backgroundImage={aboutHeader}
      headerText={"About"}
      content={content}
    />
  );
};
