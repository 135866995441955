import { SceneComponent, SceneProperties } from "types";
import Sound from "react-sound";
import { useAppSelector } from "hooks";
import { useCallback } from "react";
// Assets
import LandscapeSound from "assets/sounds/02_Landscape__4_gekuerzt.mp3";
import pavedSkySound from "assets/sounds/06_Paved_Sky.mp3";
import scienceSound from "assets/sounds/24648_Science_explore_ambience-full.mp3";

interface SceneProps extends SceneProperties {}

const SceneC: SceneComponent<SceneProps> = ({ CurrentStep }) => {
  const backgroundMusicEnabled = useAppSelector(
    (state) => state.sound.sceneBackgroundMusic.enabled
  );

  const currentStep = useAppSelector((state) => state.game.currentStep);

  const getCurrentSound = useCallback(() => {
    if (backgroundMusicEnabled) {
      return (
        <Sound
          url={pavedSkySound}
          playStatus={"PLAYING"}
          loop={true}
          volume={50}
        />
      );
    } else if (currentStep === 4) {
      return (
        <Sound
          url={scienceSound}
          playStatus={"PLAYING"}
          loop={true}
          volume={50}
        />
      );
    } else if (currentStep === 5) {
      return (
        <Sound
          url={LandscapeSound}
          playStatus={"PLAYING"}
          loop={true}
          volume={50}
        />
      );
    }
  }, [currentStep, backgroundMusicEnabled]);

  return (
    <>
      {getCurrentSound()}
      <CurrentStep />
    </>
  );
};

export default SceneC;
