// import DebugPanel from "components/DebugPanel";
import { About } from "components/otherSides/About";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Contact } from "components/otherSides/Contact";
import { Disclaimer } from "components/otherSides/Disclaimer";
import { Imprint } from "components/otherSides/Imprint";
import { isMobile, withOrientationChange } from "react-device-detect";
import { NotFound } from "components/NotFound";
import { OrientationOverlay } from "components/OrientationOverlay";
import { setScene } from "GameSlice";
import { StartPage } from "components/Startpage";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useClick } from "components/ClickProvider";
import GameManager from "GameManager";
import Map from "./components/map/Map";
import Marker from "./components/map/marker/Marker";
import SceneA from "components/scene/A";

import FairSound from "assets/sounds/05_Fair_loudness.mp3";
import logo from "assets/images/GOJELLY_Logo_v01.svg";
import mapBackgroundStart from "assets/scenes/A_01/A_01_01_MAP_v02.jpg";
import PingSound from "assets/sounds/A_01_01_ping_v01.mp3";
import ScienceSound from "assets/sounds/24648_Science_explore_ambience-full.mp3";
import SoundWaves from "assets/sounds/A_01_01_sound_v01.mp3";
import TickerSound from "assets/sounds/A_01_01_ticker-loop_v02.mp3";

import "./assets/stylesheets/Game.scss";

const Game = (props: { isPortrait?: boolean }) => {
  // Needed for navigation from one step / scene to another
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const currentScene = useAppSelector((state) => state.game.currentScene);
  // Needed for navigation from one step / scene to another
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const currentStep = useAppSelector((state) => state.game.currentStep);
  const dispatch = useAppDispatch();
  // @TODO: currentScene/currentStep Component via hook
  const CurrentSceneComponent = GameManager.currentSceneComponent();
  const CurrentStepComponent = GameManager.currentStepComponent();
  const marker = GameManager.currentMapMarker();
  const mapBackground = GameManager.currentMapBackground();
  const click = useClick();

  const firstMarker = marker[0];
  const targetMapCenter = firstMarker ? firstMarker.position : undefined;

  const { isPortrait } = props;

  const [gameIsStarted, setGameIsStarted] = useState(false);

  const onClickStartGame = useCallback(() => {
    (window as any).soundManager.setup({ debugMode: false });
    click();
    setGameIsStarted(true);
    const sounds = [
      new Audio(SoundWaves),
      new Audio(PingSound),
      new Audio(FairSound),
      new Audio(ScienceSound),
      new Audio(TickerSound),
    ];
    sounds.forEach((sound: HTMLAudioElement) => {
      sound.play();
      sound.pause();
      sound.currentTime = 0;
    });
  }, [click]);

  useEffect(() => {
    // Preload first map image
    const mapImage = new Image();
    mapImage.src = mapBackgroundStart;
  }, []);

  const goToStartPage = useCallback(() => {
    setGameIsStarted(false);
    dispatch(setScene(SceneA.IDENTIFIER));
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        <Route path="/disclaimer">
          <Disclaimer />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/imprint">
          <Imprint />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route exact path="/">
          {isPortrait && isMobile ? (
            <OrientationOverlay />
          ) : !gameIsStarted ? (
            <StartPage
              isPortrait={isPortrait}
              isMobile={isMobile}
              onClickStartGame={onClickStartGame}
            />
          ) : (
            <div className="game">
              <img
                onClick={goToStartPage}
                className="logo"
                src={logo}
                alt="Gojelly Logo"
              />
              <Map
                mapBackground={mapBackground}
                targetPosition={targetMapCenter}
              >
                {marker.map((marker, index) => (
                  <Marker
                    key={index}
                    position={marker.position}
                    onClick={marker.onClick}
                    padding={marker.padding}
                  />
                ))}
              </Map>
              <CurrentSceneComponent CurrentStep={CurrentStepComponent} />
              {/* Add this to show the debug panel */}
              {/* {!isMobile && (
                <DebugPanel displayedValues={{ currentScene, currentStep }} />
              )} */}
            </div>
          )}
        </Route>
        <Route component={NotFound}></Route>
      </Switch>
    </Router>
  );
};

export default withOrientationChange(Game);
