import ExplanationLayout from "components/prefabs/ExplanationLayout";
import styles from "../../../../prefabs/Explanation.module.scss";
import { useTranslation } from "react-i18next";
// Assets
import explanationImage from "assets/scenes/A_01/A_05_05_Grafik_v01.png";
import video from "assets/scenes/A_01/A_05_05_bg_v03.mp4";

const Explanation = () => {
  const { t } = useTranslation();

  const ExplanationAdditionalInformation = (
    <div className={styles.explanationText}>
      <ol>
        <li>{t("sceneA.step5.explanation.list1")}</li>
        <li>{t("sceneA.step5.explanation.list2")}</li>
        <li>{t("sceneA.step5.explanation.list3")}</li>
        <li>{t("sceneA.step5.explanation.list4")}</li>
        <li>{t("sceneA.step5.explanation.list5")}</li>
      </ol>
    </div>
  );

  return (
    <ExplanationLayout
      headline={t("sceneA.step5.explanation.headline")}
      video={video}
      contentImage={explanationImage}
      sideContent={ExplanationAdditionalInformation}
    ></ExplanationLayout>
  );
};

export default Explanation;
