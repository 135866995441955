import { FunctionComponent } from "react";
import { motion } from "framer-motion";
import { Position } from "types";

import styles from "./map.module.scss";

type MapProps = {
  mapBackground: string;
  targetPosition?: Position;
  movementDuration?: number;
};

/**
 * was using img.naturalHeight with an imageRef, but reference was not correctly updated,
 * so set the background height/width fix for now
 **/
const mapHeight = 2160;
const mapWidth = 3840;

const Map: FunctionComponent<MapProps> = ({
  mapBackground,
  targetPosition,
  movementDuration = 1.5,
  children,
}) => {
  const bounds = {
    top: -mapHeight + window.innerHeight,
    left: -mapWidth + window.innerWidth,
    right: 0,
    bottom: 0,
  };

  const animation = targetPosition
    ? {
        x: Math.max(
          bounds.left,
          Math.min(-targetPosition.x + window.innerWidth / 2, bounds.right)
        ),
        y: Math.max(
          bounds.top,
          Math.min(-targetPosition.y + window.innerHeight / 2, bounds.bottom)
        ),
      }
    : {};

  return (
    <div className={styles.mapContainer}>
      <motion.div
        drag
        animate={animation}
        transition={{ duration: movementDuration }}
        className={styles.map}
        dragConstraints={bounds}
      >
        <img
          src={mapBackground}
          alt="Map Background"
          style={{ pointerEvents: "none" }}
        />
        {children}
      </motion.div>
    </div>
  );
};

export default Map;
