import { StepComponent } from "types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import GameManager from "GameManager";
import SimpleVideoStep from "components/prefabs/SimpleVideoStep";
import Sound from "react-sound";
import TextTypewriter from "components/ui/TextTypewriter";

// Assets
import mapImage from "assets/scenes/D_01/D_01_01_map.jpg";
import video from "assets/scenes/E_01/E_04_01_bg.mp4";
import { isIOS, isMobile } from "react-device-detect";
import SoundWaves from "assets/sounds/A_01_01_sound_v01.mp3";

export const mapBackground: string = mapImage;

const Step4: StepComponent = () => {
  const [resetTypeWriter, setResetTypeWriter] = useState(false);
  const [playSoundWaves, setPlaySoundWaves] = useState(false);
  const [typewriterActive, setTypewriterActive] = useState(true);

  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      active={typewriterActive}
      resetTypeWriter={resetTypeWriter}
      setResetTypeWriter={setResetTypeWriter}
      timesheet={[
        {
          offset: 1,
          text: t("sceneE.step4.first"),
          waitAfter: 5.5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step4.second"),
          waitAfter: 2,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step4.third"),
          waitAfter: 3.5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step4.fourth"),
          waitAfter: 6,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step4.fifth"),
          waitAfter: 5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step4.sixth"),
          waitAfter: 5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step4.seventh"),
          waitAfter: 8.7,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step4.eigth"),
          waitAfter: 6,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step4.nineth"),
          waitAfter: 2,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneE.step4.tenth"),
        },
      ]}
    />
  );

  const onVideoEndedAdditional = useCallback(() => {
    setPlaySoundWaves(true);
  }, []);

  return (
    <>
      {!isMobile && !isIOS && playSoundWaves && (
        <Sound url={SoundWaves} playStatus={"PLAYING"} loop={true} />
      )}
      <SimpleVideoStep
        setResetTextTypeWriter={setResetTypeWriter}
        video={video}
        onClickContinue={() => GameManager.next()}
        content={{ position: "bottom", element: typewriter }}
        onVideoEndedAdditional={onVideoEndedAdditional}
        setTypewriterActive={setTypewriterActive}
      />
    </>
  );
};

const exports = { component: Step4, marker: [], mapBackground };
export default exports;
