import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { MarkerDefinition, Position } from "types";

interface InitialState {
  position: Position;
  markers: Array<MarkerDefinition>;
  isDragging: boolean;
}

const initialState: InitialState = {
  position: { x: 0, y: 0 },
  markers: [],
  isDragging: false,
};

const MapSlice = createSlice({
  name: "Map",
  initialState,
  reducers: {
    setMapPosition: (state, action: PayloadAction<Position>) => {
      state.position = action.payload;
    },
    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload;
    },
    addMarker: (state, action: PayloadAction<MarkerDefinition>) => {
      state.markers.push(action.payload);
    },
  },
});

export const mapMarkers = (state: RootState) => state.map.markers;
export const mapPosition = (state: RootState) => state.map.position;

export const { setMapPosition, setIsDragging, addMarker } = MapSlice.actions;
export default MapSlice.reducer;
