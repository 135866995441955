import { ContinueButton } from "components/ui/ContinueButton";
import { useCallback, useContext, useEffect, useState } from "react";
import FullscreenVideo from "components/video/FullscreenVideo";
import StateMachineContext from "lib/StateMachineContext";
import UserInputLayout from "components/ui/layout/UserInputLayout";
import { useTranslation } from "react-i18next";
interface AutopilotStepProps {
  typewriter: JSX.Element;
  video: string;
}

export const AutopilotStep = (props: AutopilotStepProps) => {
  const { send } = useContext(StateMachineContext);
  const [renderContent, setRenderContent] = useState(false);

  const onClickContinue = useCallback(() => {
    if (!send) return;
    send("CONTINUE");
  }, [send]);

  useEffect(() => {
    if (!renderContent) {
      setTimeout(() => {
        setRenderContent(true);
      }, 2000);
    }
  }, [renderContent]);

  const { t } = useTranslation();

  return (
    <>
      <FullscreenVideo video={props.video} resetCount={0} />
      {renderContent && (
        <UserInputLayout
          subline={t("general.autopilot")}
          content={props.typewriter}
          buttons={<ContinueButton onClick={onClickContinue} />}
        ></UserInputLayout>
      )}
    </>
  );
};
