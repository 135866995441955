import React from "react";

import styles from "./GradientOverlay.module.scss";

type GradientOverlayProps = {
  position?: "top" | "bottom" | "both";
};

const GradientOverlay = ({ position = "top" }: GradientOverlayProps) => {
  return <div className={styles[position]}></div>;
};

export default GradientOverlay;
