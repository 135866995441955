import { AutopilotStep } from "components/prefabs/AutopilotStep";
import RecommendationSlider from "components/ui/RecommendationSlider";
import TextTypewriter from "components/ui/TextTypewriter";
import { useTranslation } from "react-i18next";
// Assets
import video from "assets/scenes/D_01/D_05_04_bg.mp4";

const Autopilot = () => {
  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      timesheet={[
        {
          offset: 0,
          text: t("sceneD.step5.autopilot"),
        },
      ]}
    />
  );

  const content = (
    <>
      <RecommendationSlider
        value={-1}
        textLess={t("general.slider.lessFishing")}
        textMore={t("general.slider.moreFishing")}
      />
      <RecommendationSlider
        textLess={t("general.slider.lessAgriculture")}
        textMore={t("general.slider.moreAgriculture")}
        value={1}
      />
      <RecommendationSlider
        textLess={t("general.slider.lessFactories")}
        textMore={t("general.slider.moreFactories")}
        value={1}
      />
      <RecommendationSlider
        textLess={t("general.slider.lessFertilizer")}
        textMore={t("general.slider.moreFertilizer")}
        value={1}
      />
      <RecommendationSlider
        textLess={t("general.slider.lessAquaCulture")}
        textMore={t("general.slider.moreAquaCulture")}
        value={1}
      />
      <div className="typewriter-recomendation">{typewriter}</div>
    </>
  );
  return <AutopilotStep video={video} typewriter={content} />;
};

export default Autopilot;
