import { ContinueButton } from "components/ui/ContinueButton";
import { RepeatButton } from "components/ui/RepeatButton";
import { StepComponent } from "types";
import { useCallback, useEffect, useState } from "react";
import FullscreenVideo from "components/video/FullscreenVideo";
import TypewriterAndButtonsOverlay from "components/ui/layout/TypewriterAndButtonsOverlay";
import { PlayOrPauseButton } from "components/ui/PlayOrPauseButton";

type SimpleVideoStepProps = {
  video: string;
  onClickContinue: () => void;
  content: {
    position: "top" | "bottom";
    element: JSX.Element;
  };
  setResetTextTypeWriter?: (val: boolean) => void;
  onVideoEndedAdditional?: () => void;
  setTypewriterActive?: (val: boolean) => void;
};

const SimpleVideoStep: StepComponent<SimpleVideoStepProps> = ({
  video,
  onClickContinue,
  content,
  setResetTextTypeWriter,
  onVideoEndedAdditional,
  setTypewriterActive,
}) => {
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  const [videoIsPaused, setVideoIsPaused] = useState(false);
  const [videoIsReady, setVideoIsReady] = useState(false);

  const onVideoEnd = () => {
    if (onVideoEndedAdditional) {
      onVideoEndedAdditional();
    }
    setButtonsVisible(true);
  };

  const onClickRepeat = () => {
    setResetCount(resetCount + 1);
    if (setResetTextTypeWriter) {
      setResetTextTypeWriter(true);
    }
    setButtonsVisible(false);
  };

  const onClickPause = useCallback(() => {
    setVideoIsPaused(!videoIsPaused);
    if (setTypewriterActive) {
      setTypewriterActive(videoIsPaused);
    }
  }, [videoIsPaused, setTypewriterActive]);

  useEffect(() => {
    if (videoIsReady) {
      if (setTypewriterActive) {
        setTypewriterActive(true);
      }
    } else {
      if (setTypewriterActive) {
        setTypewriterActive(false);
      }
    }
  }, [setTypewriterActive, videoIsReady]);

  const ButtonBar = buttonsVisible ? (
    <>
      <RepeatButton onClick={onClickRepeat} />
      <ContinueButton onClick={onClickContinue} />
    </>
  ) : (
    <PlayOrPauseButton onClick={onClickPause} isVideoPaused={videoIsPaused} />
  );

  const topContent = content.position === "top" ? content.element : undefined;
  const bottomContent =
    content.position === "bottom" ? content.element : undefined;

  return (
    <FullscreenVideo
      video={video}
      onVideoEnded={onVideoEnd}
      resetCount={resetCount}
      videoIsPaused={videoIsPaused}
      setVideoIsReady={setVideoIsReady}
      videoIsReady={videoIsReady}
    >
      <TypewriterAndButtonsOverlay
        buttonBarContent={ButtonBar}
        topContent={topContent}
        bottomContent={bottomContent}
      />
    </FullscreenVideo>
  );
};

export default SimpleVideoStep;
