import React, { MouseEvent, useCallback } from "react";
import { Button } from "./Button";

import image from "assets/images/ui/button_repeat.png";
import { useClick } from "components/ClickProvider";

type Props = {
  onClick: (e: MouseEvent) => void;
};

export const RepeatButton = ({ onClick }: Props) => {
  const click = useClick();

  const onClickRepeat = useCallback(
    (event: MouseEvent) => {
      click();
      onClick(event);
    },
    [click, onClick]
  );

  return (
    <Button onClick={onClickRepeat}>
      <img src={image} alt="Button to repeat" />
    </Button>
  );
};
