import SceneE from "./Scene";
import steps from "./steps";

const IDENTIFIER = "SCENEE";

const component = SceneE;

const Scene = {
  IDENTIFIER,
  steps,
  component,
};

export default Scene;
