import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { StateMachine } from "xstate";
import { FunctionComponent } from "react";
import { useMachine } from "@xstate/react";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useCurrentCanvas = (
  stateMachine: StateMachine<any, any, any>,
  canvas: { [key: string]: FunctionComponent }
) => {
  const [state, send] = useMachine(stateMachine);
  const CurrentCanvas =
    typeof state.value == "string" ? canvas[state.value] : null;
  return {
    state,
    send,
    CurrentCanvas,
  };
};
