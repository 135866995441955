import { resetPoints, setScene } from "GameSlice";
import { SceneComponent, StepComponent } from "types";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import SceneA from "components/scene/A";

// Assets
import backgroundImage from "assets/images/gojelly_spielende_bg.jpg";
import trioDancing from "assets/images/team_dancing_top.png";
import button from "assets/images/gojelly_play-again_button_en.png";

import styles from "./End.module.scss";

type Props = { CurrentStep: StepComponent };

const IDENTIFIER = "END";

const End: SceneComponent<Props> = () => {
  const totalPoints = useAppSelector((state) => state.game.points.total);
  const youCanDoBetter = totalPoints < 1800;

  const dispatch = useAppDispatch();

  const onClickReplay = useCallback(() => {
    dispatch(resetPoints());
    dispatch(setScene(SceneA.IDENTIFIER));
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <div className={styles.outro}>
      <img className={styles.backgroundImage} src={backgroundImage} alt="" />
      <img className={styles.trioDancing} src={trioDancing} alt="" />
      <div className={styles.headline}>
        {!youCanDoBetter
          ? t("sceneE.end.headlineBad")
          : t("sceneE.end.headlineGood")}
      </div>
      {!youCanDoBetter && (
        <div className={styles.remark}>{t("sceneE.end.subline")}</div>
      )}
      <progress
        className={styles.progress}
        value={totalPoints}
        max="2400"
      ></progress>
      <div className={styles.sublineOuter}>
        <div className={styles.subline}>
          {!youCanDoBetter
            ? t("sceneE.end.informationBad")
            : t("sceneE.end.informationGood")}
        </div>
        <div className={styles.subline}>
          {t("sceneE.end.points", { points: totalPoints })}
        </div>
      </div>
      <img
        onClick={onClickReplay}
        className={styles.replayButton}
        src={button}
        alt=""
      />
    </div>
  );
};

const Scene = {
  IDENTIFIER,
  component: End,
  marker: [],
  steps: {},
};

export default Scene;
