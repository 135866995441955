import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  sceneBackgroundMusic: {
    volume: number;
    enabled: boolean;
  };
}

const initialState: InitialState = {
  sceneBackgroundMusic: {
    volume: 100,
    enabled: true,
  },
};

const SoundSlice = createSlice({
  name: "Sound",
  initialState,
  reducers: {
    sceneBackgroundMusicEnabled: (state, action: PayloadAction<boolean>) => {
      state.sceneBackgroundMusic.enabled = action.payload;
    },
  },
});

export const { sceneBackgroundMusicEnabled } = SoundSlice.actions;
export default SoundSlice.reducer;
