import { FunctionComponent, PropsWithChildren } from "react";
import { StateMachine } from "components/scene/A/steps/Step5/StateMachine";
import { StepComponent } from "types";
import { useCurrentCanvas } from "hooks";
import Autopilot from "components/scene/A/steps/Step5/Autopilot";
import Correct from "components/scene/A/steps/Step5/Correct";
import Explanation from "components/scene/A/steps/Step5/Explanation";
import Input from "components/scene/A/steps/Step5/Input";
import Intro from "components/scene/A/steps/Step5/Intro";
import StateMachineContext from "lib/StateMachineContext";
import Wrong from "components/scene/A/steps/Step5/Wrong";

const canvas: {
  [key: string]: FunctionComponent<PropsWithChildren<any> | {}>;
} = {
  Intro,
  Input,
  Wrong,
  Correct,
  Autopilot,
  Explanation,
};

const Step5: StepComponent = () => {
  const { state, send, CurrentCanvas } = useCurrentCanvas(StateMachine, canvas);

  return (
    <StateMachineContext.Provider value={{ state, send }}>
      {CurrentCanvas && <CurrentCanvas />}
    </StateMachineContext.Provider>
  );
};

const exports = { component: Step5, marker: [] };

export default exports;
