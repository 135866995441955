import { FunctionComponent, useContext } from "react";
import BottomButtonBar from "components/ui/layout/BottomButtonBar";
import StateMachineContext from "lib/StateMachineContext";
import { useTranslation } from "react-i18next";

import styles from "./UserInputLayout.module.scss";

type UserInputSceneProps = {
  subline: string;
  backgroundImage?: string;
  content: JSX.Element;
  buttons?: JSX.Element;
  showPoints?: boolean;
};

const UserInputLayout: FunctionComponent<UserInputSceneProps> = ({
  subline,
  backgroundImage,
  content,
  buttons,
  showPoints,
}) => {
  const { state } = useContext(StateMachineContext);

  const { t } = useTranslation();

  return (
    <div
      className={styles.userInputLayout}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={styles.scroll}>
        <div className={styles.userInputLayoutContent}>
          <h2>{subline}</h2>
          {showPoints && (
            <div className={styles.progressContainer}>
              <progress
                className={styles.progress}
                value={state ? state.context.pointsScene : 0}
                max="600"
              ></progress>
              <div className={styles.subline}>
                {t("general.pointsAchieved", {
                  points: state ? state.context.pointsScene : 0,
                })}
              </div>
            </div>
          )}
          <div className={styles.content}>{content}</div>
          <div className={styles.scrollPadding}></div>
        </div>
      </div>
      <BottomButtonBar>{buttons}</BottomButtonBar>
    </div>
  );
};

export default UserInputLayout;
