import { ContinueButton } from "components/ui/ContinueButton";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import BottomButtonBar from "components/ui/layout/BottomButtonBar";
import FullscreenVideo from "components/video/FullscreenVideo";
import GameManager from "GameManager";

import styles from "./Explanation.module.scss";

type ExplanationSceneProps = {
  contentImage?: string;
  headline: string;
  sideContent?: JSX.Element;
  video: string;
};

const ExplanationLayout: FunctionComponent<ExplanationSceneProps> = ({
  contentImage,
  headline,
  sideContent,
  video,
}) => {
  const [renderContent, setRenderContent] = useState(false);

  const onClickContinue = useCallback(() => {
    GameManager.next();
  }, []);

  useEffect(() => {
    if (!renderContent) {
      setTimeout(() => {
        setRenderContent(true);
      }, 2000);
    }
  }, [renderContent]);

  return (
    <>
      <FullscreenVideo video={video} resetCount={0} />
      {renderContent && (
        <div className={styles.explanationLayout}>
          <div className={styles.explanationLayoutInner}>
            <h1>{headline}</h1>
            <div className={styles.contentContainer}>
              <img className={styles.contentImage} src={contentImage} alt="" />
              {sideContent}
              <div className={styles.scrollPadding}></div>
            </div>
          </div>
        </div>
      )}
      {renderContent && (
        <BottomButtonBar>
          {<ContinueButton onClick={onClickContinue} />}
        </BottomButtonBar>
      )}
    </>
  );
};

export default ExplanationLayout;
