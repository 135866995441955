import { ClickProvider } from "components/ClickProvider";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "store";
import Game from "./Game";
import reportWebVitals from "./reportWebVitals";

// import i18n (needs to be bundled ;))
import "./helpers/i18n";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ClickProvider>
        <Game />
      </ClickProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("gameContainer")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
