import React, { FunctionComponent } from "react";

import styles from "./Layout.module.scss";

type Props = {};

const BottomButtonBar: FunctionComponent<Props> = (props) => {
  return <div className={styles.buttomButtonBar}>{props.children}</div>;
};

export default BottomButtonBar;
