import ExplanationLayout from "components/prefabs/ExplanationLayout";
import { useTranslation } from "react-i18next";
// Assets
import explanationImage from "assets/scenes/C_01/C_05_06_grafik.png";
import video from "assets/scenes/C_01/C_05_04_bg.mp4";

import styles from "../../../../prefabs/Explanation.module.scss";

const Explanation = () => {
  const { t } = useTranslation();

  const ExplanationAdditionalInformation = (
    <div className={styles.explanationTextLevelc}>
      <ol>
        <li>{t("sceneC.step5.explanation.first")}</li>
        <li>{t("sceneC.step5.explanation.second")}</li>
        <li>{t("sceneC.step5.explanation.third")}</li>
        <li>{t("sceneC.step5.explanation.fourth")}</li>
        <li>{t("sceneC.step5.explanation.fifth")}</li>
        <li>{t("sceneC.step5.explanation.sixth")}</li>
        <li>{t("sceneC.step5.explanation.seventh")}</li>
        <li>{t("sceneC.step5.explanation.eighth")}</li>
      </ol>
    </div>
  );

  return (
    <ExplanationLayout
      headline={t("sceneC.step5.explanation.headline")}
      video={video}
      contentImage={explanationImage}
      sideContent={ExplanationAdditionalInformation}
    ></ExplanationLayout>
  );
};

export default Explanation;
