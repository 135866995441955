import { Link } from "react-router-dom";
import { NavigationLinks } from "./NavigationLinks";
// Assets
import logo from "assets/images/GOJELLY_Logo_v01.svg";
import logoSimple from "assets/images/GOJELLY_Logo_simple_v01.svg";
import mapBackgroundStart from "assets/images/gojelly_startseite_bg.jpg";
import rotate from "assets/images/rotate-screen-icon.svg";

export const OrientationOverlay = () => {
  return (
    <div className="orientation-overlay">
      <Link to="/">
        <img className="logo" src={logo} alt="Gojelly Logo" />
      </Link>
      <img className="background-image" src={mapBackgroundStart} alt="" />
      <NavigationLinks />
      <div className="content">
        <img className="logo-simple" src={logoSimple} alt="Gojelly Logo" />
        <div className="headline">Save the World! *</div>
        <div className="subline">* or at least Sunset-City</div>
        <div>The game can only be played in landscape mode!</div>
        <img
          className="rotate-icon"
          src={rotate}
          alt="game is only playable in landscape mode"
        />
      </div>
    </div>
  );
};
