import { isMobile } from "react-device-detect";
import GradientOverlay from "components/ui/GradientOverlay";
import Sound from "react-sound";
import TypewriterAndButtonsOverlay from "components/ui/layout/TypewriterAndButtonsOverlay";

import SoundWaves from "assets/sounds/A_01_01_sound_v01.mp3";

interface SimpleStepComponentProps {
  typewriter: JSX.Element;
}

export const MapStep = (props: SimpleStepComponentProps) => {
  return (
    <>
      {!isMobile && (
        <Sound url={SoundWaves} playStatus={"PLAYING"} loop={true} />
      )}
      <GradientOverlay position="bottom" />
      <TypewriterAndButtonsOverlay
        bottomContent={props.typewriter}
      ></TypewriterAndButtonsOverlay>
    </>
  );
};
