import { ContinueButton } from "components/ui/ContinueButton";
import { registerDecision } from "GameSlice";
import { RepeatButton } from "components/ui/RepeatButton";
import { sceneBackgroundMusicEnabled } from "SoundSlice";
import { useAppDispatch } from "hooks";
import { useCallback, useContext, useEffect, useState } from "react";
import DecisionSlider from "components/ui/DecisionSlider";
import StateMachineContext from "lib/StateMachineContext";
import UserInputScene from "components/ui/layout/UserInputLayout";
import { useTranslation } from "react-i18next";

interface InputProps {
  setShouldResetCount: (val: boolean) => void;
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const { setShouldResetCount } = props;

  const dispatch = useAppDispatch();
  const { send } = useContext(StateMachineContext);
  const [temporaryDecisionFishing, setTemporaryDecisionFishing] = useState(0);
  const [temporaryDecisionAgriculture, setTemporaryDecisionAgriculture] =
    useState(0);
  const [
    temporaryDecisionBurgerFactories,
    setTemporaryDecisionBurgerFactories,
  ] = useState(0);
  const [temporaryDecisionFertilizer, setTemporaryDecisionFertilizer] =
    useState(0);

  useEffect(() => {
    dispatch(sceneBackgroundMusicEnabled(false));
  }, [dispatch]);

  const setDecision = () => {
    if (!send) return;
    dispatch(
      registerDecision({
        scene: "SceneC",
        decision: [
          { sliderName: "fishing", decision: temporaryDecisionFishing },
          {
            sliderName: "agriculture",
            decision: temporaryDecisionAgriculture,
          },
          {
            sliderName: "burgerfactories",
            decision: temporaryDecisionBurgerFactories,
          },
          { sliderName: "fertilizer", decision: temporaryDecisionFertilizer },
        ],
      })
    );
    send("CHOOSEN_DECISIONS", {
      decision: {
        fishing: temporaryDecisionFishing,
        agriculture: temporaryDecisionAgriculture,
        burgerfactories: temporaryDecisionBurgerFactories,
        fertilizer: temporaryDecisionFertilizer,
      },
    });
  };

  const onClickRepeat = useCallback(() => {
    dispatch(sceneBackgroundMusicEnabled(true));
    setShouldResetCount(true);
  }, [dispatch, setShouldResetCount]);

  const { t } = useTranslation();

  return (
    <UserInputScene
      subline={t("sceneC.step5.inputs")}
      content={
        <>
          <DecisionSlider
            textLess={t("general.slider.lessFishing")}
            textMore={t("general.slider.moreFishing")}
            onSetValue={setTemporaryDecisionFishing}
          />
          <DecisionSlider
            textLess={t("general.slider.lessAgriculture")}
            textMore={t("general.slider.moreAgriculture")}
            onSetValue={setTemporaryDecisionAgriculture}
          />
          <DecisionSlider
            textLess={t("general.slider.lessFactories")}
            textMore={t("general.slider.moreFactories")}
            onSetValue={setTemporaryDecisionBurgerFactories}
          />
          <DecisionSlider
            textLess={t("general.slider.lessFertilizer")}
            textMore={t("general.slider.moreFertilizer")}
            onSetValue={setTemporaryDecisionFertilizer}
          />
        </>
      }
      buttons={
        temporaryDecisionFishing !== 0 &&
        temporaryDecisionAgriculture !== 0 &&
        temporaryDecisionBurgerFactories !== 0 &&
        temporaryDecisionFertilizer !== 0 ? (
          <ContinueButton onClick={setDecision} />
        ) : (
          <>
            <RepeatButton onClick={onClickRepeat}></RepeatButton>
          </>
        )
      }
    ></UserInputScene>
  );
};

export default Input;
