import ReactSlider from "react-slider";

import styles from "./DecisionSlider.module.scss";

type RecommendatioSliderProps = {
  textLess: string;
  textMore: string;
  value: number;
};

const RecommendationSlider = ({
  textLess,
  textMore,
  value,
}: RecommendatioSliderProps) => {
  return (
    <div className={styles.decision}>
      <div className={styles.before}>{textLess}</div>
      <ReactSlider
        className={styles.slider}
        min={-1}
        max={1}
        thumbClassName={styles.thumb}
        trackClassName={styles.track}
        disabled={true}
        value={value}
      />
      <div className={styles.after}>{textMore}</div>
    </div>
  );
};

export default RecommendationSlider;
