import { useContext } from "react";
import SimpleIntroStep from "components/prefabs/SimpleIntroStep";
import StateMachineContext from "lib/StateMachineContext";

interface IntroProps {
  typewriter: JSX.Element;
  video: string;
  setInputVisible: (shouldBeVisible: boolean) => void;
  showInput: boolean;
  secondsForInput: number;
  input: JSX.Element;
  shouldResetCount: boolean;
  setShouldResetCount: (val: boolean) => void;
  setTypewriterActive?: (val: boolean) => void;
}

export const IntroStep = (props: IntroProps) => {
  const { send } = useContext(StateMachineContext);

  return (
    <>
      {props.showInput && <div className="input-container">{props.input}</div>}
      {send && (
        <SimpleIntroStep
          secondsForInput={props.secondsForInput}
          setInputVisible={props.setInputVisible}
          video={props.video}
          content={{ position: "bottom", element: props.typewriter }}
          shouldResetCount={props.shouldResetCount}
          setShouldResetCount={props.setShouldResetCount}
          setTypewriterActive={props.setTypewriterActive}
        />
      )}
    </>
  );
};
