import { StepComponent } from "types";
import { useCallback, useState } from "react";
import GameManager from "GameManager";
import SimpleVideoStep from "components/prefabs/SimpleVideoStep";
import TextTypewriter from "components/ui/TextTypewriter";
// Assets
import mapImage from "assets/scenes/A_01/A_01_01_MAP_v02.jpg";
import video from "assets/scenes/A_01/A_03_01_bg_v04.mp4";
import { useTranslation } from "react-i18next";

export const mapBackground: string = mapImage;

const Step3: StepComponent = () => {
  const [resetTypeWriter, setResetTypeWriter] = useState(false);
  const [typewriterActive, setTypewriterActive] = useState(true);

  const onClickContinue = useCallback(() => {
    GameManager.next();
  }, []);

  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      active={typewriterActive}
      resetTypeWriter={resetTypeWriter}
      setResetTypeWriter={setResetTypeWriter}
      timesheet={[
        {
          offset: 2,
          text: t("sceneA.step3.first"),
          waitAfter: 6,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneA.step3.second"),
          waitAfter: 5,
          clear: true,
        },
        {
          offset: 0,
          text: t("sceneA.step3.third"),
        },
      ]}
    />
  );
  return (
    <SimpleVideoStep
      setResetTextTypeWriter={setResetTypeWriter}
      video={video}
      onClickContinue={onClickContinue}
      content={{ position: "bottom", element: typewriter }}
      setTypewriterActive={setTypewriterActive}
    />
  );
};

const exports = { component: Step3, marker: [], mapBackground };

export default exports;
