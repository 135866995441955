import { isIOS } from "react-device-detect";
import { SceneComponent, SceneProperties } from "types";
import { useAppSelector } from "hooks";
import { useCallback } from "react";
import Sound from "react-sound";

// Assets
import ComedieSound from "assets/sounds/05_Jahzzar_-_Comedie.mp3";
import EndeavourSound from "assets/sounds/07_Jahzzar_-_Endeavour.mp3";
import ScienceSound from "assets/sounds/24648_Science_explore_ambience-full.mp3";

interface SceneProps extends SceneProperties {}

const SceneE: SceneComponent<SceneProps> = ({ CurrentStep }) => {
  const currentStep = useAppSelector((state) => state.game.currentStep);

  const getCurrentSound = useCallback(() => {
    if (currentStep === 0 && isIOS) {
      return (
        <Sound
          url={EndeavourSound}
          playStatus={"PLAYING"}
          loop={true}
          volume={50}
        />
      );
    }
    if (currentStep === 1 || currentStep === 2) {
      return (
        <Sound
          url={EndeavourSound}
          playStatus={"PLAYING"}
          loop={true}
          volume={50}
        />
      );
    } else if (currentStep === 3 && isIOS) {
      return (
        <Sound
          url={ScienceSound}
          playStatus={"PLAYING"}
          loop={true}
          volume={50}
        />
      );
    } else if (currentStep === 4) {
      return (
        <Sound
          url={ComedieSound}
          playStatus={"PLAYING"}
          loop={true}
          volume={50}
        />
      );
    }
  }, [currentStep]);

  return (
    <>
      {getCurrentSound()}
      <CurrentStep />
    </>
  );
};

export default SceneE;
