import { OtherSide } from "./OtherSide";
// Assets
import disclaimerImage from "assets/images/gojelly_site_bg_01.jpg";

import styles from "./OtherSides.module.scss";

export const Disclaimer = () => {
  const content = (
    <div className={styles.contentText}>
      <p>
        <strong>{`LIABILITY FOR CONTENT`}</strong>
        <br />
        <br />
        {`We make every effort to keep the information on our Website current,
        but accept no liability whatsoever for the content provided. Pursuant
        to §7 par. 1 of TMG (German Tele-Media Act), the law limits our responsibility as a 
        service provider to our own content on these Web pages.`}
      </p>
      <p>
        {`According to §§8 to 10 of TMG , we are not obligated to monitor third party information provided or
        stored on our Web site. However, we shall promptly remove any content 
        upon becoming aware that it violates the law. Our liability in such an 
        instance shall commence at the time we become aware of the respective violation`}
      </p>
      <p>
        <strong>{`LIABILITY FOR LINKS`}</strong>
        <br />
        <br />
        {`Our site contains links to third-party Web sites. We have no influence whatsoever 
        on the information on these Web sites and accept no guarantee for its correctness. 
        The content of such third-party sites is the responsibility of the respective 
        owners/providers. At the time third-party Web sites were linked to ours, we found 
        NO GROUNDS WHATSOEVER of any likely contravention of the law. We shall promptly delete a 
        link upon becoming aware that it violates the law.`}
      </p>
      <p>
        <strong>{`COPYRIGHTS`}</strong>
        <br />
        <br />
        {`The content and works provided on these Web pages are governed by the copyright 
        laws of Germany. Duplication, processing, distribution, or any form of 
        commercialization of such material beyond the scope of the copyright law shall 
        require the prior written consent of its respective author or creator.`}
      </p>
      <p>
        <strong>{`DATA PROTECTION`}</strong>
        <br />
        <br />
        {`Please be aware that there are inherent security risks in transmitting data, 
        such as e-mails, via the Internet, because it is impossible to safeguard completely 
        against unauthorized access by third parties. Nevertheless, we shall safeguard your 
        data, subject to this limitation. In particular, personal information will be 
        transmitted via the Internet only if it does not infringe upon third-party rights, 
        unless the respective party has given its prior consent in view of such security risks. 
        Accordingly, as the Web site provider, we shall not be held liable for any damages 
        incurred as a consequence of such security risks or for any related acts of 
        omission on our part.`}
      </p>
      <p>
        {`We oppose the use of any available contact information by a third party for sending 
        unsolicited advertisements. As the Web site provider, we reserve the express right to 
        take legal action against unsolicited mailing or e-mailing of spam and other similar 
        advertising materials.`}
      </p>
      <p>
        source: <a href={"https://www.erecht24.de/"}>eRecht24.de</a>
      </p>
    </div>
  );

  return (
    <OtherSide
      backgroundImage={disclaimerImage}
      headerText={"Disclaimer"}
      content={content}
    />
  );
};
