import SceneA from "components/scene/A";
import SceneB from "components/scene/B";
import SceneC from "components/scene/C";
import SceneD from "components/scene/D";
import SceneE from "components/scene/E";
import SceneEnd from "components/scene/End";
import { SceneGraph } from "types";
import { store } from "store";
import {
  setStep as setStepAction,
  setScene as setSceneAction,
} from "GameSlice";

import defaultMapBackground from "assets/scenes/A_01/A_01_01_MAP_v02.jpg";

const sceneGraph: SceneGraph = {
  [SceneA.IDENTIFIER]: {
    ...SceneA,
    nextScene: SceneB.IDENTIFIER,
  },
  [SceneB.IDENTIFIER]: {
    ...SceneB,
    nextScene: SceneC.IDENTIFIER,
  },
  [SceneC.IDENTIFIER]: {
    ...SceneC,
    nextScene: SceneD.IDENTIFIER,
  },
  [SceneD.IDENTIFIER]: {
    ...SceneD,
    nextScene: SceneE.IDENTIFIER,
  },
  [SceneE.IDENTIFIER]: {
    ...SceneE,
    nextScene: SceneEnd.IDENTIFIER,
  },
  [SceneEnd.IDENTIFIER]: {
    ...SceneEnd,
  },
};

const setScene = (sceneIdentifier: string) => {
  store.dispatch(setSceneAction(sceneIdentifier));
};

const setStep = (step: number) => {
  store.dispatch(setStepAction(step));
};
const nextScene = () => {
  const currentSceneIdentifier = store.getState().game.currentScene;
  if (!sceneGraph[currentSceneIdentifier])
    throw new Error(
      `Scene ${currentSceneIdentifier} has no definition in sceneGraph`
    );

  const scene = sceneGraph[currentSceneIdentifier].nextScene;
  const newCurrentScene = scene ? scene : currentSceneIdentifier;
  store.dispatch(setSceneAction(newCurrentScene));
};

const sceneHasNextStep = () => {
  const currentStep = store.getState().game.currentStep;

  const currentSceneDefinition = currentScene();
  if (!currentSceneDefinition.steps) return false;
  return Object.values(currentSceneDefinition.steps).length > currentStep + 1;
};
const next = () => {
  const currentSceneIdentifier = store.getState().game.currentScene;
  const currentStep = store.getState().game.currentStep;
  if (sceneHasNextStep()) {
    store.dispatch(setStepAction(currentStep + 1));
  } else {
    store.dispatch(
      setSceneAction(
        sceneGraph[currentSceneIdentifier].nextScene || SceneEnd.IDENTIFIER
      )
    );
  }
};

const currentScene = () => {
  const currentSceneIdentifier = store.getState().game.currentScene;
  if (!sceneGraph[currentSceneIdentifier])
    throw new Error("Scene has no definition in sceneGraph");
  return sceneGraph[currentSceneIdentifier];
};

const currentStep = () => {
  const currentStepIdentifier = store.getState().game.currentStep;
  return currentScene().steps[currentStepIdentifier];
};

const currentSceneComponent = () => {
  return currentScene().component;
};

const currentStepComponent = () => {
  const currentStep = store.getState().game.currentStep;
  const currentSceneIdentifier = store.getState().game.currentScene;
  if (!sceneGraph[currentSceneIdentifier])
    throw new Error("Scene has no definition in sceneGraph");
  if (
    !sceneGraph[currentSceneIdentifier].steps ||
    Object.values(sceneGraph[currentSceneIdentifier].steps).length === 0
  )
    return <></>;
  if (!sceneGraph[currentSceneIdentifier].steps[currentStep])
    throw new Error(`${currentSceneIdentifier} has no step ${currentStep}`);
  return sceneGraph[currentSceneIdentifier].steps[currentStep].component;
};

const currentMapMarker = () => {
  return currentStep() ? currentStep().marker : [];
};

const currentMapBackground = (): string => {
  const stepMap = currentStep()
    ? currentStep().mapBackground
    : defaultMapBackground;
  return stepMap || defaultMapBackground;
};

const firstScene = SceneA.IDENTIFIER;

const Scenes = {
  Scenes: sceneGraph,
  firstScene,
  nextScene,
  next,
  currentSceneComponent,
  currentStepComponent,
  currentMapMarker,
  sceneHasNextStep,
  setScene,
  setStep,
  currentMapBackground,
};

export default Scenes;
