import { ContinueButton } from "components/ui/ContinueButton";
import { registerPoints } from "GameSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useContext, useEffect, useState } from "react";
import FullscreenVideo from "components/video/FullscreenVideo";
import StateMachineContext from "lib/StateMachineContext";
import TextTypewriter from "components/ui/TextTypewriter";
import UserInputLayout from "components/ui/layout/UserInputLayout";
import { useTranslation } from "react-i18next";
interface CorrectStepProps {
  typewriterText: string;
  video: string;
}

export const CorrectStep = (props: CorrectStepProps) => {
  const { send, state } = useContext(StateMachineContext);
  const currentScene = useAppSelector((state) => state.game.currentScene);
  const dispatch = useAppDispatch();

  const [renderContent, setRenderContent] = useState(false);

  const onClickContinue = () => {
    if (!send) return;
    send("CONTINUE");
  };

  useEffect(() => {
    dispatch(
      registerPoints({
        scene: currentScene,
        points: state ? state.context.pointsScene : 0,
      })
    );
  }, [currentScene, dispatch, state]);

  useEffect(() => {
    if (!renderContent) {
      setTimeout(() => {
        setRenderContent(true);
      }, 2000);
    }
  }, [renderContent]);

  const typewriter = (
    <TextTypewriter
      timesheet={[
        {
          offset: 0,
          text: props.typewriterText,
        },
      ]}
    />
  );

  const { t } = useTranslation();

  return (
    <>
      <FullscreenVideo video={props.video} resetCount={0} />
      {renderContent && (
        <UserInputLayout
          subline={t("general.correct")}
          content={typewriter}
          buttons={<ContinueButton onClick={onClickContinue} />}
          showPoints={true}
        ></UserInputLayout>
      )}
    </>
  );
};
