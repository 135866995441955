import { ContinueButton } from "components/ui/ContinueButton";
import { registerPoints } from "GameSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback, useContext, useEffect, useState } from "react";
import FullscreenVideo from "components/video/FullscreenVideo";
import StateMachineContext from "lib/StateMachineContext";
import UserInputLayout from "components/ui/layout/UserInputLayout";

interface WrongStepProps {
  subline: string;
  typeWriter: JSX.Element;
  video: string;
}

export const WrongStep = (props: WrongStepProps) => {
  const { send, state } = useContext(StateMachineContext);
  const currentScene = useAppSelector((state) => state.game.currentScene);
  const dispatch = useAppDispatch();

  const [renderContent, setRenderContent] = useState(false);

  const onClickContinue = useCallback(() => {
    if (!send) return;
    send("CONTINUE");
  }, [send]);

  useEffect(() => {
    dispatch(
      registerPoints({
        scene: currentScene,
        points: state ? state.context.pointsScene : 0,
      })
    );
  }, [currentScene, dispatch, state]);

  useEffect(() => {
    if (!renderContent) {
      setTimeout(() => {
        setRenderContent(true);
      }, 2000);
    }
  }, [renderContent]);

  return (
    <>
      <FullscreenVideo video={props.video} resetCount={0} />
      {renderContent && (
        <UserInputLayout
          subline={props.subline}
          content={props.typeWriter}
          buttons={<ContinueButton onClick={onClickContinue} />}
          showPoints={true}
        ></UserInputLayout>
      )}
    </>
  );
};
