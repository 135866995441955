import { Link } from "react-router-dom";
import { NavigationLinks } from "./NavigationLinks";
import { useTranslation } from "react-i18next";
// Assets
import button from "assets/images/gojelly_start_button_en.png";
import logo from "assets/images/GOJELLY_Logo_v01.svg";
import logoSimple from "assets/images/GOJELLY_Logo_simple_v01.svg";
import mapBackgroundStart from "assets/images/gojelly_startseite_bg.jpg";

import styles from "../components/ui/Button.module.scss";
import { isMobile } from "react-device-detect";

interface StartpageProps {
  onClickStartGame: () => void;
  isMobile: boolean;
  isPortrait?: boolean;
}

export const StartPage = (props: StartpageProps) => {
  const { t, i18n } = useTranslation();

  const lngs: { [key: string]: { nativeName: string } | undefined } = {
    en: { nativeName: "English" },
    de: { nativeName: "Deutsch" },
  };

  return (
    <div className="start-screen">
      <Link to="/">
        <img className="logo" src={logo} alt="Gojelly Logo" />
      </Link>
      <img className="background-image" src={mapBackgroundStart} alt="" />
      <NavigationLinks />
      <div className="content-container">
        <img className="logo-simple" src={logoSimple} alt="Gojelly Logo" />
        <div className="headline">{t("startpage.title")}</div>
        <div className="subline">{t("startpage.subline")}</div>
        <img
          onClick={props.onClickStartGame}
          className="play-now-button"
          src={button}
          alt=""
        />
        <div className="startpage-lngs">
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              className={styles.startPage}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
                backgroundColor:
                  i18n.resolvedLanguage === lng ? "#E401FF" : "#b1b3b1",
                color: i18n.resolvedLanguage === lng ? "#fff" : "#000",
                fontSize: isMobile ? "1.7vw" : "16px",
              }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
              {lngs[lng]?.nativeName ?? lngs["en"]}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
