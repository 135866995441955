import { Click } from "./ClickSound";
import { isMobile } from "react-device-detect";
import React, { useCallback, useContext, useEffect, useState } from "react";

const clickContext = React.createContext(() => {});

export const useClick = () => {
  return useContext(clickContext);
};

export const ClickProvider = ({ children }: { children: React.ReactNode }) => {
  const [clickSoundPlaying, setClickSoundPlaying] = useState(false);

  const setSound = useCallback(() => {
    setClickSoundPlaying(true);
  }, []);

  useEffect(() => {
    if (clickSoundPlaying) {
      setTimeout(() => {
        setClickSoundPlaying(false);
      }, 600);
    }
  }, [clickSoundPlaying]);

  return (
    <>
      {clickSoundPlaying && !isMobile && <Click />}
      <clickContext.Provider value={setSound}>{children}</clickContext.Provider>
    </>
  );
};
