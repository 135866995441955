import { AnyEventObject } from "xstate";

export const isLess = (val: number) => {
  return val === -1;
};

export const isMore = (val: number) => {
  return val === 1;
};

export const isLessFish = (context: Step5Context, _event: AnyEventObject) => {
  return isLess(context.decisionFish);
};

export const isMoreFish = (context: Step5Context, _event: AnyEventObject) => {
  return isMore(context.decisionFish);
};

export const isLessAgriculture = (
  context: Step5Context,
  _event: AnyEventObject
) => {
  return isLess(context.decisionAgriculture);
};

export const isMoreAgriculture = (
  context: Step5Context,
  _event: AnyEventObject
) => {
  return isMore(context.decisionAgriculture);
};

export const isLessBurgerFactories = (
  context: Step5Context,
  _event: AnyEventObject
) => {
  return isLess(context.decisionBurgerFactories);
};

export const isMoreBurgerFactories = (
  context: Step5Context,
  _event: AnyEventObject
) => {
  return isMore(context.decisionBurgerFactories);
};

export const isLessFertilizer = (
  context: Step5Context,
  _event: AnyEventObject
) => {
  return isLess(context.decisionFertilizer);
};

export const isMoreFertilizer = (
  context: Step5Context,
  _event: AnyEventObject
) => {
  return isMore(context.decisionFertilizer);
};

export const isLessAquaCulture = (
  context: Step5Context,
  _event: AnyEventObject
) => {
  return isLess(context.decisionAquaCulture);
};

export const isMoreAquaCulture = (
  context: Step5Context,
  _event: AnyEventObject
) => {
  return isMore(context.decisionAquaCulture);
};

export enum SolutionState {
  TooMany,
  TooFew,
  QuiteCorrect,
  Correct,
}

export const addPoints = (points: number, solutionState: SolutionState) => {
  const changeContext = (context: Step5Context, _event: AnyEventObject) => {
    context.pointsScene = points;
    context.solutionState = solutionState;
  };
  return changeContext;
};

export interface Step5Context {
  decisionFish: number;
  decisionAgriculture: number;
  decisionBurgerFactories: number;
  decisionFertilizer: number;
  decisionAquaCulture: number;
  pointsScene: number;
  solutionState: SolutionState;
}

export const initialStep5Context = {
  decisionFish: 0,
  decisionAgriculture: 0,
  decisionBurgerFactories: 0,
  decisionFertilizer: 0,
  decisionAquaCulture: 0,
  pointsScene: 0,
  solutionState: SolutionState.Correct,
};
