import { CorrectStep } from "components/prefabs/CorrectStep";
// Assets
import video from "assets/scenes/A_01/A_05_04_bg_v02.mp4";
import { useTranslation } from "react-i18next";

const Correct = () => {
  const { t } = useTranslation();

  return (
    <CorrectStep video={video} typewriterText={t("sceneA.step5.correct")} />
  );
};

export default Correct;
