import { CorrectStep } from "components/prefabs/CorrectStep";
import { useContext } from "react";
import StateMachineContext from "lib/StateMachineContext";
import { useTranslation } from "react-i18next";
// Assets
import video from "assets/scenes/D_01/D_05_05_bg.mp4";

const Correct = () => {
  const { state } = useContext(StateMachineContext);

  const { t } = useTranslation();

  const scenePoints = state?.context.pointsScene;
  return (
    <CorrectStep
      video={video}
      typewriterText={
        scenePoints === 600
          ? t("sceneD.step5.correct.correct")
          : t("sceneD.step5.correct.quiteCorrect")
      }
    />
  );
};

export default Correct;
