import { Button } from "./Button";
import { MouseEvent, useCallback } from "react";
import { useClick } from "components/ClickProvider";

import image from "assets/images/ui/A_05_01_weiter.png";

type Props = {
  onClick: (e: MouseEvent) => void;
};

export const ContinueButton = ({ onClick }: Props) => {
  const click = useClick();

  const onClickContinue = useCallback(
    (event: MouseEvent) => {
      click();
      onClick(event);
    },
    [click, onClick]
  );

  return (
    <Button onClick={onClickContinue}>
      <img src={image} alt="button to continue" />
    </Button>
  );
};
