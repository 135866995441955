import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

const sceneSteps = {
  0: { ...Step1 },
  1: { ...Step2 },
  2: { ...Step3 },
  3: { ...Step4 },
  4: { ...Step5 },
  5: { ...Step6 },
};

export default sceneSteps;
