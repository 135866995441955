import { MapStep } from "components/prefabs/MapStep";
import { MarkerDefinition, StepComponent } from "types";
import GameManager from "GameManager";
import TextTypewriter from "components/ui/TextTypewriter";
// Assets
import mapImage from "assets/scenes/A_01/A_04_01_MAP_v02.jpg";
import { useTranslation } from "react-i18next";

export const mapBackground: string = mapImage;

export const marker: Array<MarkerDefinition> = [
  {
    position: { x: 2814, y: 1491 },
    onClick: () => {
      GameManager.next();
    },
    padding: {
      bottom: 183,
    },
  },
];

const Step4: StepComponent = () => {
  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      timesheet={[
        {
          offset: 1,
          text: t("sceneA.step4"),
        },
      ]}
    />
  );
  return <MapStep typewriter={typewriter} />;
};

const exports = { component: Step4, marker, mapBackground };

export default exports;
