import { WrongStep } from "components/prefabs/WrongStep";
import TextTypewriter from "components/ui/TextTypewriter";
import { useTranslation } from "react-i18next";
// Assets
import video from "assets/scenes/A_01/A_05_02_bg_v02.mp4";

const Wrong = () => {
  const { t } = useTranslation();

  const typewriter = (
    <TextTypewriter
      timesheet={[
        {
          offset: 0,
          text: t("sceneA.step5.wrong.explanation"),
        },
      ]}
    />
  );
  return (
    <WrongStep
      subline={t("general.wrongSolution")}
      typeWriter={typewriter}
      video={video}
    />
  );
};

export default Wrong;
